import React, { useEffect, useState } from "react";
import { Card, Grid, Typography, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import FormField from "../ajouter-articles/components/FormField";
import SupplierApi from "api/supplier";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import SoftTypography from "components/SoftTypography";
import ProductsApi from "api/products";
import SoftSelect from "components/SoftSelect";

function NewSupplier() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const [suppliersList, setSuppliersList] = useState([
    { name: "", phone: "", email: "", address: "", supplier_products: [] },
  ]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);

  const isLargeScreen = window.innerWidth > 767;
  const rightPosition =
    i18n.language === "ar" && isLargeScreen
      ? window.innerWidth >= 2000
        ? "-326%"
        : window.innerWidth >= 1900
        ? "-330%"
        : window.innerWidth >= 1850
        ? "-339%"
        : window.innerWidth >= 1800
        ? "-339%"
        : window.innerWidth >= 1750
        ? "-343%"
        : window.innerWidth >= 1700
        ? "-349%"
        : window.innerWidth >= 1650
        ? "-352%"
        : window.innerWidth >= 1600
        ? "-360%"
        : window.innerWidth >= 1550
        ? "-364%"
        : window.innerWidth >= 1500
        ? "-371%"
        : window.innerWidth >= 1450
        ? "-293.5%"
        : window.innerWidth >= 1400
        ? "-296%"
        : window.innerWidth >= 1350
        ? "-299.5%"
        : window.innerWidth >= 1300
        ? "-302%"
        : window.innerWidth >= 1250
        ? "-306%"
        : window.innerWidth >= 1200
        ? "-310%"
        : window.innerWidth >= 1150
        ? "-257%"
        : window.innerWidth >= 1100
        ? "-257%"
        : window.innerWidth >= 1050
        ? "-259%"
        : window.innerWidth >= 1000
        ? "-261%"
        : window.innerWidth >= 950
        ? "-262%"
        : window.innerWidth >= 900
        ? "-264%"
        : window.innerWidth >= 850
        ? "-266%"
        : window.innerWidth >= 800
        ? "-268%"
        : "-270%"
      : "0";

  const handleAddSupplier = () => {
    setSuppliersList([
      ...suppliersList,
      { name: "", phone: "", email: "", address: "", supplier_products: [] },
    ]);
  };

  const handleRemoveSupplier = (index) => {
    if (suppliersList.length > 1) {
      const updatedSuppliers = suppliersList.filter((_, i) => i !== index);
      setSuppliersList(updatedSuppliers);
    }
  };

  const handleChange = (index, field, value) => {
    setError("");

    const updatedSuppliers = suppliersList.map((supplier, i) =>
      i === index ? { ...supplier, [field]: value } : supplier
    );
    setSuppliersList(updatedSuppliers);
  };

  const handleProductsChange = (index, selectedProducts) => {
    setError("");
    const updatedSuppliers = suppliersList.map((supplier, i) =>
      i === index
        ? {
            ...supplier,
            supplier_products: selectedProducts.map((opt) => opt.value),
          }
        : supplier
    );
    setSuppliersList(updatedSuppliers);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setProductsLoading(true);
    ProductsApi.GetProductsPrices(token)
      .then((response) => {
        setProducts(response.data);
        setProductsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setProductsLoading(false);
      });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    const token = localStorage.getItem("token");

    try {
      for (const supplier of suppliersList) {
        if (
          !supplier.name ||
          !supplier.phone ||
          !supplier.email ||
          !supplier.address
        ) {
          setError("Tous les champs sont obligatoires.");
          setLoading(false);
          return;
        }
      }
      for (const supplier of suppliersList) {
        // Extract only the IDs
        const productIds = supplier.supplier_products;

        await SupplierApi.CreateSupplier(token, {
          name: supplier.name,
          phone: supplier.phone,
          email: supplier.email,
          address: supplier.address,
          supplier_products: productIds, // Pass only the IDs
          company: 1,
        });
      }

      setSuppliersList([
        { name: "", phone: "", email: "", address: "", supplier_products: [] },
      ]);
      navigate("/gestion-de-stock/listes-des-fournisseurs");
    } catch (err) {
      setError("Erreur lors de la création des fournisseurs.");
      console.error("Create supplier error:", err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setProductsLoading(true);
    // Fetch products
    ProductsApi.GetProductsPrices(token)
      .then((response) => {
        setProducts(response.data);
        setProductsLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
        setProductsLoading(false);
      });
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3}>
          <Card>
            <SoftBox p={3}>
              <Box>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {t("Créer des fournisseurs")}
                </Typography>

                {error && <Typography color="error">{error}</Typography>}

                <form onSubmit={handleSubmit}>
                  {suppliersList.map((supplier, index) => (
                    <Grid container spacing={2.5} key={index}>
                      <Grid item xs={12} md={2}>
                        <FormField
                          label={t("Nom")}
                          fullWidth
                          value={supplier.name}
                          onChange={(e) =>
                            handleChange(index, "name", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <FormField
                          label={t("Téléphone")}
                          fullWidth
                          value={supplier.phone}
                          onChange={(e) =>
                            handleChange(index, "phone", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <FormField
                          label={t("Email")}
                          fullWidth
                          value={supplier.email}
                          onChange={(e) =>
                            handleChange(index, "email", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <FormField
                          label={t("Adresse")}
                          fullWidth
                          value={supplier.address}
                          onChange={(e) =>
                            handleChange(index, "address", e.target.value)
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <SoftTypography
                          component="label"
                          variant="caption"
                          fontWeight="bold"
                          textTransform="capitalize"
                        >
                          {t("Produits")}
                        </SoftTypography>
                        <SoftSelect
                          isMulti
                          value={supplier.supplier_products?.map((id) => {
                            const product = products.find((p) => p.id === id);
                            return product
                              ? {
                                  value: product.id,
                                  label: product.product_name,
                                }
                              : null;
                          })}
                          onChange={(selectedOptions) =>
                            handleProductsChange(index, selectedOptions)
                          }
                          options={
                            productsLoading
                              ? [{ value: "", label: t("Chargement...") }]
                              : products.map((product) => ({
                                  value: product.id,
                                  label: product.product_name,
                                }))
                          }
                          placement="auto"
                          menuPortalTarget={document.body}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              right: rightPosition,
                            }),
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={1} my={4}>
                        <IconButton
                          color="error"
                          onClick={() => handleRemoveSupplier(index)}
                          disabled={suppliersList.length === 1}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                  <Box display="flex" mt={2}>
                    <SoftButton
                      variant="outlined"
                      color="info"
                      onClick={handleAddSupplier}
                    >
                      <AddIcon /> {t("Ajouter un fournisseur")}
                    </SoftButton>
                  </Box>
                  <Box display="flex" justifyContent="flex-end" mt={2}>
                    <SoftButton
                      variant="contained"
                      color="info"
                      type="submit"
                      disabled={loading}
                    >
                      {loading ? t("Création...") : t("Créer")}
                    </SoftButton>
                  </Box>
                </form>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewSupplier;
