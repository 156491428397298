import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import SoftTypography from "components/SoftTypography";
import FormField from "./components/FormField";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import NotificationsApi from "api/notifications";
import InputEmoji from "react-input-emoji";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";

function NewNotification() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [notificationInfo, setNotificationInfo] = useState({
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [text, setText] = useState("");

  const validateForm = () => {
    let valid = true;
    const errors = {};

    if (!text.trim()) {
      errors.title = "Le titre est requis !";
      valid = false;
    }

    if (!notificationInfo.description.trim()) {
      errors.description = "La description est requise !";
      valid = false;
    }

    setErrors(errors);
    return valid;
  };

  const handleNotificationInfoChange = (fieldName, value) => {
    setErrors({});
    setNotificationInfo({
      ...notificationInfo,
      [fieldName]: value,
    });
  };

  const handleSave = () => {
    if (!validateForm()) {
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);

    const payload = {
      title: text, // Assuming the title comes from the InputEmoji component
      description: notificationInfo.description,
    };

    NotificationsApi.CreateNotification(payload, token)
      .then((response) => {
        navigate("/gestion-des-promotions/liste-des-notifications");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} mt={10}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Card elevation={3} style={{ overflow: "visible" }}>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom>
                    {t("Informations sur la notification")}
                  </SoftTypography>
                  <SoftBox mt={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12}>
                        <InputEmoji
                          type="text"
                          placeholder={t("Titre de la notification")}
                          error={errors.title}
                          cleanOnEnter
                          value={text}
                          onChange={(value) => {
                            setText(value);
                            setErrors({ ...errors, title: "" });
                          }}
                          onEnter={handleSave}
                        />
                        <SoftTypography
                          component="error"
                          color="error"
                          variant="caption"
                        >
                          {errors.title}
                        </SoftTypography>
                      </Grid>
                      <Grid item xs={12}>
                        <FormField
                          type="text"
                          label={t("Description")}
                          placeholder={t("Description")}
                          value={notificationInfo.description}
                          onChange={(e) =>
                            handleNotificationInfoChange(
                              "description",
                              e.target.value
                            )
                          }
                          error={errors.description}
                        />
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <SoftButton
                      disabled={loading}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("Enregistrer")
                      )}
                    </SoftButton>
                  </SoftBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewNotification;
