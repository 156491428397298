import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftTypography from "components/SoftTypography";
import DynamicDataTable from "examples/Tables/DynamicTable";
import OrdersApi from "api/orders";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

function ShoppingItemsDetails() {
  const { client_id, page } = useParams();
  const [orders, setOrders] = useState([]);
  const [shoppingItems, setShoppingItems] = useState([]);
  const [client, setClient] = useState("");
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      setLoading(false);
      return;
    }

    OrdersApi.GetShoppingItems(token, page)
      .then((response) => {
        if (response.data) {
          setOrders(response.data.results);
        } else {
          console.warn("No data found for client ID:", client_id);
        }
      })
      .catch((error) => {
        console.error("Error fetching shopping items:", error);
      })
      .finally(() => setLoading(false));
  }, [client_id, page]);

  const getTableRows = (items) => {
    return items.flatMap((item) => {
      if (Array.isArray(item)) {
        return item.map((i) => ({
          client: client,
          product_name: i.product_name || "N/A",
          quantity: i.quantity || 0,
          total_price: `${(i.total_price || 0).toFixed(3)} TND`,
          image: i.image || "",
        }));
      }
      return [];
    });
  };

  const dataTableDataOrders = {
    columns: [
      {
        Header: `${t("Client")}`,
        accessor: "client",
      },
      {
        Header: `${t("Nom du produit")}`,
        accessor: "product_name",
        width: 300,
        Cell: ({ row }) => (
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* {row.original.image && (
              <img
                src={row.original.image}
                alt={row.original.product_name}
                style={{ width: 80, height: 80, marginRight: 10 }}
              />
            )} */}
            <Typography variant="body2" style={{ fontSize: 14 }}>
              {row.original.product_name}
            </Typography>
          </div>
        ),
      },
      { Header: `${t("Quantité")}`, accessor: "quantity", width: 120 },
      { Header: `${t("Prix de box")}`, accessor: "total_price", width: 120 },
    ],
    rows: getTableRows(shoppingItems),
  };

  useEffect(() => {
    if (orders.length > 0) {
      const selectedClient = orders.find(
        (order) => order.client_id === client_id
      );
      const clientShoppingItems = selectedClient?.shopping_items || [];
      setShoppingItems(clientShoppingItems);
      setClient(selectedClient?.client)
    }
  }, [orders, client_id]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox
          p={{ xl: 5 }}
          style={{
            width: "100%",
            height: "60%",
            marginTop: shoppingItems.length > 2 ? 0 : "5%",
            marginBottom: shoppingItems.length > 2 ? 0 : "5%",
          }}
        >
          <SoftBox>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <Grid container justifyContent="center">
                <Grid item xs={12} xl={8}>
                  <Card>
                    <SoftBox pt={3} px={2}>
                      <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <SoftBox>
                          <SoftBox mb={1}>
                            <SoftTypography variant="h6" fontWeight="medium">
                              {t("Détails des produits")}
                            </SoftTypography>
                          </SoftBox>
                        </SoftBox>
                      </SoftBox>
                    </SoftBox>
                    <SoftBox pb={3} px={2}>
                      <SoftBox>
                        <DynamicDataTable
                          columns={dataTableDataOrders.columns}
                          rows={dataTableDataOrders.rows}
                        />
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Grid>
              </Grid>
            )}
          </SoftBox>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default ShoppingItemsDetails;
