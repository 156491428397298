import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import GooglePlacesInput from "../maps/GooglePlacesInput";
import GoogleMaps from "../maps/GoogleMaps";
import { LoadScript } from "@react-google-maps/api";
import { useTranslation } from "react-i18next";

function Address({ formData, onChange, validateFormCallback, touched }) {
  const { values } = formData;
  const { latitude: initialLatitude, longitude: initialLongitude } = values;
  // eslint-disable-next-line
  const [typedPlace, setTypedPlace] = useState("");
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [latitude, setLatitude] = useState(initialLatitude || null);
  const [longitude, setLongitude] = useState(initialLongitude || null);
  const [addressDetails, setAddressDetails] = useState(
    values.address_details || ""
  );
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  useEffect(() => {
    validateForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDetails, latitude, longitude]);

  const handleLocationChange = ({ latitude, longitude, address_details }) => {
    setLatitude(latitude);
    setLongitude(longitude);
    setAddressDetails(address_details);
  };

  const handlePlaceSelected = (place) => {
    if (place) {
      setSelectedLocation({
        lat: place.latitude,
        lng: place.longitude,
      });
      setLatitude(place.latitude);
      setLongitude(place.longitude);
      setAddressDetails(place.address);
      setErrors({});
    }
  };

  const handleInputChange = (inputValue) => {
    setTypedPlace(inputValue);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!addressDetails && !latitude && !longitude) {
      newErrors.address_details = `${t(
        "Veuillez sélectionner une adresse sur la carte ou saisir une adresse et sélectionner une suggestion"
      )}`;
    }
    setErrors(newErrors);
    validateFormCallback(Object.keys(newErrors).length === 0);
  };

  useEffect(() => {
    if (touched) {
      validateForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [touched]);

  useEffect(() => {
    onChange({
      address_details: addressDetails,
      latitude: latitude,
      longitude: longitude,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addressDetails, latitude, longitude]);

  return (
    <SoftBox>
      <SoftTypography variant="h5" fontWeight="bold">
        {t("Adresse")}
      </SoftTypography>
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        libraries={["places"]}
        language="fr"
        region="tn"
      >
        <SoftBox mt={1.625}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <GooglePlacesInput
                onPlaceSelected={handlePlaceSelected}
                onInputChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <GoogleMaps
            onLocationChange={handleLocationChange}
            selectedLocation={selectedLocation}
          />
          {touched && errors.address_details && (
            <SoftTypography variant="caption" color="error">
              {errors.address_details}
            </SoftTypography>
          )}
          {touched && errors.coordinates && (
            <SoftTypography variant="caption" color="error">
              {errors.coordinates}
            </SoftTypography>
          )}
        </SoftBox>
      </LoadScript>
    </SoftBox>
  );
}

Address.propTypes = {
  formData: PropTypes.shape({
    formField: PropTypes.shape({
      address_details: PropTypes.object.isRequired,
    }).isRequired,
    values: PropTypes.object.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  validateFormCallback: PropTypes.func.isRequired,
  touched: PropTypes.bool.isRequired,
};

export default Address;
