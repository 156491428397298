import { useState } from "react";
import { Formik, Form } from "formik";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import form from "./schemas/form";
import { useNavigate } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import AuthApi from "api/auth";
import initialValues from "./schemas/initialValues";
import UserInfo from "./components/UserInfo";
import Address from "./components/Address";
import Media from "./components/Media";
import { useTranslation } from "react-i18next";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function getSteps() {
  return ["Informations de client", "Média", "Adresse"];
}

function NewUser() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();
  const { formId } = form;
  const [userInfo, setUserInfo] = useState({});
  const [mediaInfo, setMediaInfo] = useState({});
  // eslint-disable-next-line
  const [addressInfo, setAddressInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [touchedSteps, setTouchedSteps] = useState(false);

  const isLastStep = activeStep === steps.length - 1;

  const handleBack = () => setActiveStep(activeStep - 1);

  const handleSubmit = async () => {
    const payload = {
      address_detail: addressInfo.address_details || "",
      business_role_id: userInfo.user_type?.value || "",
      city: addressInfo.city || "",
      first_activity: userInfo.first_activity?.value || "",
      firstname: userInfo.firstname || "",
      id_card_image: mediaInfo.id_card_image || "",
      lastname: userInfo.lastname || "",
      latitude: addressInfo.latitude || "",
      longitude: addressInfo.longitude || "",
      mat_fiscale: userInfo.mat_fiscale || "",
      password: userInfo.password || "",
      password2: userInfo.password2 || "",
      patent_image: mediaInfo.patent_image || "",
      phone: userInfo.phone || "",
      second_activity: userInfo.second_activity?.value || "",
      store_type: userInfo.store_type?.value || "",
      username: userInfo.username || "",
      regime_forfaitaire: userInfo?.regime_forfaitaire || false,
    };

    if (isLastStep) {
      try {
        setLoading(true);
        await AuthApi.Register(payload);
        navigate("/pages/users/liste-des-clients");
        setActiveStep(0);
      } catch (error) {
        setLoading(false);
        console.error("Registration failed:", error);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleGoBack = () => navigate(-1);

  const handleUserInfoChange = (newInfo) => {
    setUserInfo((prevInfo) => ({ ...prevInfo, ...newInfo }));
    setFormValid(false);
  };

  const handleMediaChange = (newInfo) => {
    setMediaInfo((prevInfo) => ({ ...prevInfo, ...newInfo }));
    setFormValid(false);
  };

  const handleAddressInfoChange = (newInfo) => {
    setAddressInfo((prevInfo) => ({ ...prevInfo, ...newInfo }));
    setFormValid(false);
  };

  function getStepContent(stepIndex, formData) {
    switch (stepIndex) {
      case 0:
        return (
          <UserInfo
            formData={formData}
            onChange={handleUserInfoChange}
            validateFormCallback={setFormValid}
            touched={touchedSteps}
          />
        );
      case 1:
        return (
          <Media
            onChange={handleMediaChange}
            validateFormCallback={setFormValid}
            touched={touchedSteps}
          />
        );
      case 2:
        return (
          <Address
            formData={formData}
            onChange={handleAddressInfoChange}
            validateFormCallback={setFormValid}
            touched={touchedSteps}
          />
        );
      default:
        return null;
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3} mb={3}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            {/* Go Back Button */}
            <SoftBox mb={2}>
              <SoftButton
                variant="outlined"
                color="secondary"
                onClick={handleGoBack}
                startIcon={<ArrowBackIcon />}
                sx={{
                  borderWidth: 2, 
                  padding: "8px 16px",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                {t("Retour")}
              </SoftButton>
            </SoftBox>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{t(label)}</StepLabel>
                </Step>
              ))}
            </Stepper>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {
                if (!formValid) {
                  setTouchedSteps(true);
                  setActiveStep(activeStep);
                } else {
                  setTouchedSteps(false);
                  if (isLastStep) {
                    handleSubmit();
                  } else {
                    setActiveStep(activeStep + 1);
                  }
                }
              }}
              validateOnChange={false}
            >
              {({ values, touched, isSubmitting, validateForm }) => (
                <Form id={formId} autoComplete="off">
                  <Card>
                    <SoftBox p={2}>
                      {getStepContent(activeStep, {
                        values,
                        touched,
                      })}
                      <SoftBox
                        mt={2}
                        width="100%"
                        display="flex"
                        justifyContent="space-between"
                      >
                        {activeStep === 0 ? (
                          <SoftBox />
                        ) : (
                          <SoftButton
                            variant="gradient"
                            color="light"
                            onClick={handleBack}
                          >
                            {t("Retour")}
                          </SoftButton>
                        )}
                        <SoftButton
                          disabled={loading}
                          type="submit"
                          variant="gradient"
                          color="dark"
                        >
                          {loading ? (
                            <CircularProgress size={24} color="inherit" />
                          ) : isLastStep ? (
                            `${t("Enregistrer")}`
                          ) : (
                            `${t("Suivant")}`
                          )}
                        </SoftButton>
                      </SoftBox>
                    </SoftBox>
                  </Card>
                </Form>
              )}
            </Formik>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default NewUser;
