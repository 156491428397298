import { useEffect, useState } from "react";
import { Card, CircularProgress, Grid } from "@mui/material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import ManagersApi from "api/managers";
import SoftTypography from "components/SoftTypography";
import SoftSelect from "components/SoftSelect";
import WarehouseApi from "api/warehouse";

function NewManager() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    password: "",
    confirm_password: "",
    firstname: "",
    lastname: "",
    phone: "",
    poste: "warehouse_manager",
    business_role_id: "warehouse_manager",
    warehouse: { id: null, name: "" },
    username: "",
  });

  const [loading, setLoading] = useState(false);
  const [loadingWarehouses, setLoadingWarehouses] = useState(false);
  const [errors, setErrors] = useState({});
  const [warehouses, setWarehouses] = useState([]);
  const token = localStorage.getItem("token");

  // Fetch the warehouse list on component mount
  useEffect(() => {
    WarehouseApi.GetWarehouseList(token)
      .then((response) => {
        setWarehouses(response.data); // Update state with the API response data
        setLoadingWarehouses(false);
      })
      .catch((error) => {
        console.error("Error fetching warehouse list:", error);
        setLoadingWarehouses(false);
      });
  }, [token]);

  // Update username when firstname or lastname changes
  useEffect(() => {
    if (formData.firstname && formData.lastname) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        username: `${formData.firstname.replace(
          / /g,
          "_"
        )}_${formData.lastname.replace(/ /g, "_")}`,
      }));
    }
  }, [formData.firstname, formData.lastname]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstname) newErrors.firstname = t("Le prénom est requis.");
    if (!formData.lastname) newErrors.lastname = t("Le nom est requis.");
    if (!formData.phone) {
      newErrors.phone = t("Le téléphone est requis.");
    } else if (!/^\d{8}$/.test(formData.phone)) {
      newErrors.phone = t("Le téléphone doit comporter exactement 8 chiffres.");
    }
    if (!formData.password)
      newErrors.password = t("Le mot de passe est requis.");
    if (formData.password !== formData.confirm_password) {
      newErrors.confirm_password = t("Les mots de passe ne correspondent pas.");
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const token = localStorage.getItem("token");
    if (!token) {
      console.error(t("Token non trouvé"));
      return;
    }

    try {
      setLoading(true);
      const { warehouse, ...restFormData } = formData;
      const payload = { ...restFormData, warehouse_id: warehouse.id };

      await ManagersApi.CreateManager(token, payload);
      navigate("/gestion-des-ventes/liste-des-managers");
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={2} sx={{ px: { lg: 15, xl: "20%" } }}>
          <Card>
            <SoftBox py={3} px={5}>
              {/* <Typography variant="h6" fontWeight="bold" mb={2}>
                {t("Créer un gestionnaire d'entrepôt")}
              </Typography> */}
              <SoftBox component="form" onSubmit={handleSubmit}>
                <Grid container spacing={3.5}>
                  {/* Grid with 3 columns */}
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Prénom")}
                    </SoftTypography>
                    <SoftInput
                      name="firstname"
                      placeholder={t("Entrez votre prénom")}
                      value={formData.firstname}
                      onChange={handleChange}
                      fullWidth
                      error={!!errors.firstname}
                    />
                    <SoftTypography variant="caption" color="error">
                      {errors.firstname}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Nom de famille")}
                    </SoftTypography>
                    <SoftInput
                      name="lastname"
                      placeholder={t("Entrez votre nom de famille")}
                      value={formData.lastname}
                      onChange={handleChange}
                      fullWidth
                      error={!!errors.lastname}
                    />
                    <SoftTypography variant="caption" color="error">
                      {errors.lastname}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Poste")}
                    </SoftTypography>
                    <SoftSelect
                      name="poste"
                      placeholder={t("Manager d'entrepôt")}
                      value={{
                        value: formData.poste,
                        label: t("Manager d'entrepôt"),
                      }}
                      options={[
                        {
                          value: "warehouse_manager",
                          label: t("Manager d'entrepôt"),
                        },
                      ]}
                      onChange={(selectedOption) => {
                        setFormData({
                          ...formData,
                          poste: selectedOption.value,
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Entrepôt")}
                    </SoftTypography>
                    <SoftSelect
                      name="warehouse"
                      placeholder={t("Sélectionnez un entrepôt")}
                      value={
                        formData?.warehouse?.id
                          ? {
                              value: formData.warehouse.id,
                              label: formData.warehouse.name,
                            }
                          : null
                      }
                      options={
                        loadingWarehouses
                          ? [
                              {
                                value: "",
                                label: (
                                  <CircularProgress
                                    size={20}
                                    sx={{ color: "#1F51FF", marginLeft: 7 }}
                                  />
                                ),
                              },
                            ]
                          : warehouses.length > 0
                          ? warehouses.map((warehouse) => ({
                              value: warehouse.id,
                              label: warehouse.warehouse_name,
                            }))
                          : [
                              {
                                value: "",
                                label: t("Aucun entrepôt disponible"),
                              },
                            ]
                      }
                      onChange={(selectedOption) => {
                        setFormData({
                          ...formData,
                          warehouse: selectedOption
                            ? {
                                id: selectedOption.value,
                                name: selectedOption.label,
                              }
                            : { id: null, name: "" },
                        });
                      }}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Téléphone")}
                    </SoftTypography>
                    <SoftInput
                      name="phone"
                      type="number"
                      placeholder={t("Téléphone")}
                      value={formData.phone}
                      onChange={handleChange}
                      fullWidth
                      error={!!errors.phone}
                    />
                    <SoftTypography variant="caption" color="error">
                      {errors.phone}
                    </SoftTypography>
                  </Grid>
                  {/* Grid with 2 columns */}
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Mot de passe")}
                    </SoftTypography>
                    <SoftInput
                      name="password"
                      type="password"
                      placeholder={t("Mot de passe")}
                      value={formData.password}
                      onChange={handleChange}
                      fullWidth
                      error={!!errors.password}
                    />
                    <SoftTypography variant="caption" color="error">
                      {errors.password}
                    </SoftTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SoftTypography variant="h6" mb={1}>
                      {t("Confirmer le mot de passe")}
                    </SoftTypography>
                    <SoftInput
                      name="confirm_password"
                      type="password"
                      placeholder={t("Confirmer le mot de passe")}
                      value={formData.confirm_password}
                      onChange={handleChange}
                      fullWidth
                      error={!!errors.confirm_password}
                    />
                    <SoftTypography variant="caption" color="error">
                      {errors.confirm_password}
                    </SoftTypography>
                  </Grid>
                </Grid>

                <SoftBox mt={4} display="flex" justifyContent="flex-end">
                  <SoftButton variant="gradient" color="info" type="submit">
                    {loading ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      t("Créer")
                    )}
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewManager;
