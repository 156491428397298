import React, { useEffect, useState } from "react";
import { Card, CircularProgress, Menu, MenuItem } from "@mui/material";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DataTable from "examples/Tables/DataTable";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import ActionCell from "./components/ActionCell";
import SupplierApi from "api/supplier";
import { useTranslation } from "react-i18next";
import SupplierCell from "components/SoftTooltip";

function SuppliersList() {
  const { t } = useTranslation();

  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchor, setAnchor] = useState(null);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const handleSortChange = (field) => {
    const newSortOrder =
      sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(newSortOrder);

    const sortedSuppliers = [...suppliers].sort((a, b) => {
      const valueA =
        field === "created_at" ? new Date(a[field]) : a[field]?.toLowerCase();
      const valueB =
        field === "created_at" ? new Date(b[field]) : b[field]?.toLowerCase();

      if (newSortOrder === "asc") {
        return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
      } else {
        return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
      }
    });

    setSuppliers(sortedSuppliers);
    setAnchor(null); // Close the menu after sorting
  };

  // Fetch suppliers from the API
  useEffect(() => {
    const fetchSuppliers = async () => {
      const token = localStorage.getItem("token");
      try {
        const supplierData = await SupplierApi.GetSuppliersList(token);
        setSuppliers(supplierData.data.results); // Update suppliers state with response data
      } catch (error) {
        console.error("Failed to fetch suppliers:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSuppliers();
  }, []);

  // Define columns for the table
  const columns = [
    { Header: `${t("Nom")}`, accessor: "name" },
    { Header: `${t("Téléphone")}`, accessor: "phone" },
    { Header: `${t("Adresse")}`, accessor: "address" },
    { Header: `${t("Email")}`, accessor: "email" },
    {
      Header: `${t("Produits")}`,
      accessor: "products",
      Cell: ({ value }) => <SupplierCell value={value} />, // Render the product names
    },
    { Header: t("action"), accessor: "action" },
  ];

  // Map suppliers to rows for the table
  const rows = suppliers?.map((supplier) => ({
    name: supplier.name,
    phone: supplier.phone,
    email: supplier.email,
    address: supplier.address || t("Non spécifié"),
    products: supplier.products.length > 0
      ? supplier.products.map((product) => product.product_name)
      : t("Aucun produit"),
    created_at: new Date(supplier.created_at).toLocaleDateString("fr-FR"),
    action: <ActionCell supplierID={supplier.id} />,
  }));

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <Card sx={{ my: 5 }}>
          <SoftBox mb={{ lg: -7 }} pt={3} px={3}>
            <Link to="/gestion-de-stock/nouveau-fournisseur">
              <SoftButton
                variant="gradient"
                color="info"
                sx={{ marginRight: 2 }}
              >
                + {t("Nouveau fournisseur")}
              </SoftButton>
            </Link>
            <SoftButton
              variant="outlined"
              color="dark"
              onClick={(e) => setAnchor(e.currentTarget)}
            >
              {t("Trier par nom ou date")} (⬆⬇)
            </SoftButton>
            <Menu
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={() => setAnchor(null)}
            >
              <MenuItem onClick={() => handleSortChange("name")}>
                {t("Trier par Nom")} (
                {sortField === "name" && sortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
              <MenuItem onClick={() => handleSortChange("created_at")}>
                {t("Trier par Date")} (
                {sortField === "created_at" && sortOrder === "asc" ? "⬆" : "⬇"})
              </MenuItem>
            </Menu>
          </SoftBox>
          <SoftBox>
            {!loading ? (
              <DataTable
                table={{ columns, rows }}
                entriesPerPage={false}
                canSearch={rows?.length > 0}
                columnMinWidth={70}
              />
            ) : (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            )}
          </SoftBox>
        </Card>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default SuppliersList;
