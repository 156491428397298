import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useNavigate } from "react-router-dom";
import FormField from "layouts/pages/account/components/FormField";
import SoftButton from "components/SoftButton";
import PaymentApi from "api/payment";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

// CreateBank component: Renders the form to create a new bank with validation
function CreateBank() {
  const { t } = useTranslation();

  // State to manage bank input values
  const [bank, setBank] = useState({
    name: "",
    swift_code: "",
    created_at: "",
    address: "",
  });

  // State to handle form submission loading state
  const [loading, setLoading] = useState(false);

  // State to manage validation errors for form fields
  const [errors, setErrors] = useState({
    name: "",
    swift_code: "",
    created_at: "",
    address: "",
  });

  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  // Handles input changes and updates the bank state
  const handleChange = (e) => {
    setBank({ ...bank, [e.target.name]: e.target.value });
    validateField(e.target.name, e.target.value); // Validate input on change
  };

  // Validates the form fields
  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "name":
        error = value.trim() === "" ? t("Le nom est requis") : "";
        break;
      case "swift_code":
        error = value.trim() === "" ? t("Le numéro de compte est requis") : "";
        break;
      case "address":
        error = value.trim() === "" ? t("L'adresse est requise") : "";
        break;
      default:
        break;
    }

    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  // Validates all fields before submission
  const validateForm = () => {
    const newErrors = {
      name: bank.name.trim() === "" ? t("Le nom est requis") : "",
      swift_code:
        bank.swift_code.trim() === ""
          ? t("Le numéro de compte est requis")
          : "",
      address: bank.address.trim() === "" ? t("L'adresse est requise") : "",
    };

    setErrors(newErrors);

    // Return true if no errors
    return Object.values(newErrors).every((error) => error === "");
  };

  // Handles form submission and calls API to create a bank
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return; // Stop submission if validation fails

    setLoading(true); // Set loading state to true

    try {
      // API call to create a bank
      await PaymentApi.CreateBank(token, bank);

      // Redirect to bank list page on successful creation
      navigate("/gestion-financiere/liste-des-banques");
    } catch (err) {
      console.error("Failed to create the bank. Please try again.");
    } finally {
      setLoading(false); // Set loading state to false after API call
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={2}>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6}>
              <Card>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom pb={5} pt={2}>
                    {t("Créer une banque")}
                  </SoftTypography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Nom")}
                        name="name"
                        value={bank.name}
                        onChange={handleChange}
                        error={!!errors.name}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Numéro de compte")}
                        name="swift_code"
                        value={bank.swift_code}
                        onChange={handleChange}
                        error={!!errors.swift_code}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Adresse")}
                        name="address"
                        value={bank.address}
                        onChange={handleChange}
                        error={!!errors.address}
                        required
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormField
                        fullWidth
                        label={t("Date de création")}
                        name="created_at"
                        type="date"
                        value={bank.created_at}
                        onChange={handleChange}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} display="flex" justifyContent="flex-end">
                      <SoftButton
                        type="submit"
                        variant="contained"
                        color="info"
                        onClick={handleSubmit}
                        disabled={loading} // Disable button while loading
                      >
                        {loading ? (
                          <CircularProgress size={24} color="white" />
                        ) : (
                          t("Créer la banque")
                        )}
                      </SoftButton>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default CreateBank;
