import React, { useState } from "react";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import { Link, useLocation } from "react-router-dom";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import OrdersApi from "api/orders";
import { useTranslation } from "react-i18next";
import { CircularProgress } from "@mui/material";

function ActionCell({ orderId, onDataUpdate }) {
  const location = useLocation();
  const { t } = useTranslation();

  const [open, setOpen] = useState({ confirm: false, cancel: false });
  const [loading, setLoading] = useState({ confirm: false, cancel: false });

  const handleOpen = (type) => {
    setOpen((prevState) => ({ ...prevState, [type]: true }));
  };

  const handleClose = (type) => {
    setOpen((prevState) => ({ ...prevState, [type]: false }));
  };

  const handleConfirmAction = () => {
    setLoading((prevState) => ({ ...prevState, confirm: true }));
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      setLoading((prevState) => ({ ...prevState, confirm: false }));
      return;
    }

    OrdersApi.DeliverOnWayOrders(token, orderId)
      .then((response) => {
        handleClose("confirm");
        onDataUpdate();
      })
      .catch((error) => {
        console.error(
          `Error confirming order ${orderId}:`,
          error.response?.data?.msg || error.message
        );
        handleClose("confirm");
        onDataUpdate();
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, confirm: false }));
      });
  };

  const handleCancelAction = () => {
    setLoading((prevState) => ({ ...prevState, cancel: true }));
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      setLoading((prevState) => ({ ...prevState, cancel: false }));
      return;
    }

    OrdersApi.CancelOnWayOrders(token, orderId)
      .then((response) => {
        handleClose("cancel");
        onDataUpdate();
      })
      .catch((error) => {
        console.error(
          `Error canceling order ${orderId}:`,
          error.response?.data?.msg || error.message
        );
        handleClose("cancel");
        onDataUpdate();
      })
      .finally(() => {
        setLoading((prevState) => ({ ...prevState, cancel: false }));
      });
  };

  return (
    <SoftBox display="flex" alignItems="center">
      <SoftTypography
        variant="body1"
        color="grey-400"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title={t("Aperçu de la commande")} placement="top">
          <Link
            to={`/gestion-des-commandes/details-de-commande/${orderId}`}
            state={{ from: location.pathname }}
            style={{ color: "inherit" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.1)",
              },
            }}
          >
            <Icon>visibility</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>
      <SoftBox mx={2}>
        <SoftTypography
          variant="body1"
          color="success"
          sx={{ cursor: "pointer", lineHeight: 0 }}
        >
          <Tooltip title={t("Livrer la commande")} placement="top">
            <Link
              to="#"
              onClick={() => handleOpen("confirm")}
              style={{ color: "inherit" }}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px",
                height: "40px",
                borderRadius: "50%",
                transition: "background-color 0.3s ease",
                "&:hover": {
                  backgroundColor: "rgba(0, 255, 0, 0.1)",
                },
              }}
            >
              <Icon>check</Icon>
            </Link>
          </Tooltip>
        </SoftTypography>
      </SoftBox>
      <SoftTypography
        variant="body1"
        color="error"
        sx={{ cursor: "pointer", lineHeight: 0 }}
      >
        <Tooltip title={t("Annuler la commande")} placement="left">
          <Link
            to="#"
            onClick={() => handleOpen("cancel")}
            style={{ color: "inherit" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              transition: "background-color 0.3s ease",
              "&:hover": {
                backgroundColor: "rgba(255, 0, 0, 0.1)",
              },
            }}
          >
            <Icon>close</Icon>
          </Link>
        </Tooltip>
      </SoftTypography>

      {/* Confirm Action Modal */}
      <Dialog
        open={open.confirm}
        onClose={() => handleClose("confirm")}
        aria-labelledby="confirm-dialog-title"
        aria-describedby="confirm-dialog-description"
      >
        <DialogTitle id="confirm-dialog-title">
          {t("Confirmer la commande")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-dialog-description">
            {t("Êtes-vous sûr de vouloir livrer cette commande ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            {t("Non")}
          </Button>
          <Button
            onClick={handleConfirmAction}
            color="primary"
            autoFocus
            disabled={loading.confirm}
          >
            {loading.confirm ? (
              <CircularProgress size={24} sx={{ color: "#1F51FF" }} />
            ) : (
              t("Oui")
            )}{" "}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Cancel Action Modal */}
      <Dialog
        open={open.cancel}
        onClose={() => handleClose("cancel")}
        aria-labelledby="cancel-dialog-title"
        aria-describedby="cancel-dialog-description"
      >
        <DialogTitle id="cancel-dialog-title">
          {t("Annuler la commande")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="cancel-dialog-description">
            {t("Êtes-vous sûr de vouloir annuler cette commande ?")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose("cancel")} color="primary">
            {t("Non")}
          </Button>
          <Button
            onClick={handleCancelAction}
            color="primary"
            autoFocus
            disabled={loading.cancel}
          >
            {loading.cancel ? (
              <CircularProgress size={24} sx={{ color: "#1F51FF" }} />
            ) : (
              t("Oui")
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </SoftBox>
  );
}

export default ActionCell;
