import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import ProductsApi from "api/products";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import SoftTypography from "components/SoftTypography";
import FormField from "./components/FormField";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftSelect from "components/SoftSelect";
import Footer from "examples/Footer";
import { useTranslation } from "react-i18next";

function NewPromotion() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [promotionInfo, setPromotionInfo] = useState({
    name: "",
    product_id: "",
    discount_rate: "",
    start_date: "",
    end_date: "",
  });
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [products, setProducts] = useState([]);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    setLoading(true);
    ProductsApi.GetAllProducts(token)
      .then((response) => {
        setProducts(response.data);
      })
      .catch((error) => {
        console.error("Error fetching products:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const validate = () => {
    let valid = true;
    const newErrors = {};

    if (!promotionInfo.name) {
      newErrors.name = t("Le nom de la promotion est requis !");
      valid = false;
    }

    if (!promotionInfo.product_id) {
      newErrors.product_id = t("Le produit est requis !");
      valid = false;
    }

    if (!promotionInfo.discount_rate) {
      newErrors.discount_rate = t("Le taux de remise est requis !");
      valid = false;
    }

    if (!promotionInfo.start_date) {
      newErrors.start_date = t("La date de début est requise !");
      valid = false;
    }

    if (!promotionInfo.end_date) {
      newErrors.end_date = t("La date de fin est requise !");
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handlePromotionInfoChange = (newInfo) => {
    setErrors({});
    setPromotionInfo({
      ...promotionInfo,
      ...newInfo,
    });
    if (errors[newInfo.name]) {
      setErrors({
        ...errors,
        [newInfo.name]: "",
      });
    }
  };

  const handleSave = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    if (!validate()) {
      return;
    }

    setLoadingSave(true);

    const payload = {
      name: promotionInfo.name,
      product_id: promotionInfo.product_id.value,
      discount_rate: promotionInfo.discount_rate,
      start_date: promotionInfo.start_date,
      end_date: promotionInfo.end_date,
    };

    ProductsApi.CreateNewPromotedProduct(payload, token)
      .then((response) => {
        navigate("/gestion-des-promotions/liste-des-promotions");
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message);
      })
      .finally(() => {
        setLoadingSave(false);
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} mt={10}>
          <Grid container justifyContent="center" sx={{ height: "100%" }}>
            <Grid item xs={12} sm={10} md={8} lg={6}>
              <Card elevation={3}>
                <CardContent>
                  <SoftTypography variant="h5" gutterBottom>
                    {t("Informations sur la promotion")}
                  </SoftTypography>
                  <SoftBox mt={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="text"
                          label={t("Nom de la promotion")}
                          placeholder={t("Nom de la promotion")}
                          value={promotionInfo.name}
                          onChange={(e) =>
                            handlePromotionInfoChange({
                              name: e.target.value,
                            })
                          }
                          error={errors.name}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SoftBox mb={2}>
                          <SoftTypography
                            component="label"
                            variant="caption"
                            fontWeight="bold"
                            textTransform="capitalize"
                          >
                            {t("Produit")}
                          </SoftTypography>
                          <SoftSelect
                            label={t("Produit")}
                            placeholder={t("Produit")}
                            value={promotionInfo.product_id}
                            onChange={(newValue) =>
                              handlePromotionInfoChange({
                                product_id: newValue,
                              })
                            }
                            options={
                              loading
                                ? [
                                    {
                                      value: "",
                                      label: (
                                        <SoftBox
                                          display="flex"
                                          justifyContent="center"
                                          alignItems="center"
                                          minWidth="100%"
                                          minHeight="48px"
                                        >
                                          <CircularProgress
                                            size={20}
                                            sx={{
                                              color: "#1F51FF",
                                              marginLeft: 7,
                                            }}
                                          />
                                        </SoftBox>
                                      ),
                                    },
                                  ]
                                : products.length > 0
                                ? products.map((product) => ({
                                    value: product.id,
                                    label: product.product_name,
                                  }))
                                : [
                                    {
                                      value: "",
                                      label: t("Aucun produit disponible"),
                                    },
                                  ]
                            }
                            errorLabel={errors.product_id}
                          />
                        </SoftBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="number"
                          label={t("Taux de remise")}
                          placeholder={t("Taux de remise")}
                          value={promotionInfo.discount_rate}
                          onChange={(e) =>
                            handlePromotionInfoChange({
                              discount_rate: e.target.value,
                            })
                          }
                          error={errors.discount_rate}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="datetime-local"
                          label={t("Date de Début")}
                          value={promotionInfo.start_date}
                          onChange={(e) => {
                            const newStartDate = e.target.value;
                            // If the new start date is after the current end date, clear the end date
                            if (
                              new Date(newStartDate) >
                              new Date(promotionInfo.end_date)
                            ) {
                              setPromotionInfo({
                                ...promotionInfo,
                                start_date: newStartDate,
                                end_date: "", // Clear end date if it's invalid
                              });
                            } else {
                              setPromotionInfo({
                                ...promotionInfo,
                                start_date: newStartDate,
                              });
                            }
                          }}
                          error={errors.start_date}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          type="datetime-local"
                          label={t("Date de Fin")}
                          value={promotionInfo.end_date}
                          min={promotionInfo.start_date} // Set the minimum date to start date
                          onChange={(e) => {
                            const newEndDate = e.target.value;
                            if (
                              new Date(newEndDate) <
                              new Date(promotionInfo.start_date)
                            ) {
                              // Optionally handle invalid end date case
                              setErrors({
                                ...errors,
                                end_date: t(
                                  "La date de fin ne peut pas être avant la date de début !"
                                ),
                              });
                            } else {
                              setPromotionInfo({
                                ...promotionInfo,
                                end_date: newEndDate,
                              });
                            }
                          }}
                          error={errors.end_date}
                        />
                      </Grid>
                    </Grid>
                  </SoftBox>
                  <SoftBox mt={2} display="flex" justifyContent="flex-end">
                    <SoftButton
                      disabled={loadingSave}
                      type="submit"
                      variant="gradient"
                      color="dark"
                      onClick={handleSave}
                    >
                      {loadingSave ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("Enregistrer")
                      )}
                    </SoftButton>
                  </SoftBox>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewPromotion;
