import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import StockApi from "../../../api/stock";
import ActionCell from "./components/ActionCell";
import { exportDataToCSV } from "utils/csvUtils";
import SoftButton from "components/SoftButton";
import { Link } from "react-router-dom";
import { Menu, MenuItem, Switch, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import SupplierCell from "components/SoftTooltip";
import SoftTypography from "components/SoftTypography";

function Stock() {
  const { t } = useTranslation();

  const [stock, setStock] = useState([]);
  const [loading, setLoading] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [showOutOfStock, setShowOutOfStock] = useState(false);

  useEffect(() => {
    handleGetStock();
    // eslint-disable-next-line
  }, [showOutOfStock]);

  const handleGetStock = () => {
    const token = localStorage.getItem("token");

    if (!token) {
      console.error("No token found in localStorage.");
      return;
    }

    StockApi.GetStock(token, showOutOfStock)
      .then((response) => {
        if (response) {
          setStock(response.data);
        } else {
          console.warn("No data found:", response.data.msg);
        }
      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response data:", error.response.data.msg);
          console.error("Full error response:", error.response);
        } else {
          console.error("There has been an error.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const tableData = {
    columns: [
      { Header: `${t("Code de référence")}`, accessor: "reference_code" },
      { Header: `${t("Nom du produit")}`, accessor: "product_name" },
      {
        Header: `${t("Fournisseur")}`,
        accessor: "supplier",
        Cell: ({ value }) => <SupplierCell value={value} />,
      },
      { Header: `${t("Stock Initial")}`, accessor: "initial_stock" },
      { Header: `${t("Entrée")}`, accessor: "increases" },
      { Header: `${t("Sortie")}`, accessor: "decreases" },
      { Header: `${t("Stock Final")}`, accessor: "final_stock" },
      {
        Header: `${t("Valeur du Stock Final")}`,
        accessor: "final_stock_value",
      },
      { Header: t("action"), accessor: "action" },
    ],
    rows: stock?.map((item) => ({
      reference_code: item.reference_code,
      product_name: item.product_name,
      supplier: item.suppliers.includes(null)
        ? [t("Fournisseur non spécifié")]
        : item.suppliers,
      decreases: item.decreases,
      final_stock: item.final_stock,
      initial_stock: item.initial_stock,
      increases: item.increases,
      final_stock_value: item.final_stock_value,
      action: <ActionCell id={item.product_id} />,
    })),
  };

  const handleExportToCSV = () => {
    const exportColumns = tableData.columns.filter(
      (column) => column.accessor !== "action"
    );
    const exportRows = tableData.rows.map((row) => {
      const { action, ...exportRow } = row;
      return exportRow;
    });
    exportDataToCSV(exportColumns, exportRows, "stock.csv");
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleToggleOutOfStock = () => {
    setShowOutOfStock((prevState) => !prevState);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          {!loading && (
            <SoftBox display="flex" justifyContent="space-between" mb={2}>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={handleMenuClick}
              >
                {t("Gérer le Stock")}
              </SoftButton>
              <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
              >
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/gestion-de-stock/nouveau-stock"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {t("Nouveau stock")}
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/gestion-de-stock/augmenter-le-stock"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {t("Augmenter le stock")}
                  </Link>
                </MenuItem>
                <MenuItem onClick={handleMenuClose}>
                  <Link
                    to="/gestion-de-stock/stock-de-tous-les-entrepots"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    {t("Demande tranfert de stock")}
                  </Link>
                </MenuItem>
              </Menu>
              <SoftBox display="flex" alignItems="center">
                <SoftTypography
                  style={{ fontSize: 14, fontWeight: "bold", marginRight: 20 }}
                >
                  {t("Produits en rupture de stock")}
                </SoftTypography>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showOutOfStock}
                      onChange={handleToggleOutOfStock}
                      color="primary"
                    />
                  }
                />
                <SoftButton
                  variant="gradient"
                  color="info"
                  onClick={handleExportToCSV}
                >
                  {t("Exporter la Situation")}
                </SoftButton>
              </SoftBox>
            </SoftBox>
          )}
          <Card>
            {loading ? (
              <SoftBox
                display="flex"
                justifyContent="center"
                alignItems="center"
                p={35}
              >
                <CircularProgress sx={{ color: "#1F51FF" }} />
              </SoftBox>
            ) : (
              <DataTable
                table={tableData}
                entriesPerPage={{
                  defaultValue: 10,
                  entries: [5, 10, 15, 20, 25],
                }}
                canSearch
                showTotalEntries={false}
              />
            )}
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default Stock;
