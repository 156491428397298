import React, { useState, useEffect } from "react";
import { Card, Grid, Typography, Box, IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import SoftBox from "components/SoftBox";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import MultiSelect from "components/MultiSelect";
import { useNavigate } from "react-router-dom";
import WarehouseApi from "api/warehouse";
import { useTranslation } from "react-i18next";

function NewWarehouse() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState([
    { name: "", city: "", address: "", managers: [] },
  ]);
  const [warehousesList, setWarehousesList] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state
  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchWarehouseManagers = async () => {
      try {
        const response = await WarehouseApi.GetWarehouseManagersList(token);
        const managersList = response.data.map((manager) => ({
          label:
            manager.firstname && manager.lastname
              ? `${manager.firstname} ${manager.lastname}`
              : "",
          value: manager.id,
        }));
        setWarehousesList(managersList);
      } catch (error) {
        console.error("Failed to fetch warehouse managers:", error);
      }
    };

    fetchWarehouseManagers();
  }, [token]);

  const handleWarehouseChange = (index, name, value) => {
    const updatedWarehouses = [...warehouses];
    updatedWarehouses[index][name] = value;
    setWarehouses(updatedWarehouses);
  };

  const handleAddWarehouse = () => {
    setWarehouses([
      ...warehouses,
      { name: "", city: "", address: "", managers: [] },
    ]);
  };

  const handleRemoveWarehouse = (index) => {
    const updatedWarehouses = warehouses.filter((_, i) => i !== index);
    setWarehouses(updatedWarehouses);
  };

  const handleSubmit = async () => {
    setLoading(true);
    try {
      // Map and send a request for each warehouse
      await Promise.all(
        warehouses.map((warehouse) =>
          WarehouseApi.CreateWarehouse(token, {
            warehouse_name: warehouse.name,
            warehouse_city: warehouse.city,
            location: warehouse.address,
            manager: warehouse.managers,
          })
        )
      );

      // Navigate to the warehouse list or show success message
      navigate("/entreprise/informations-entrepots");
    } catch (error) {
      console.error("Failed to create one or more warehouses:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox py={3} sx={{ px: { lg: 14 } }}>
          <Card>
            <SoftBox py={3} px={5}>
              <Box>
                <Typography variant="h6" fontWeight="bold" mb={2}>
                  {t("Créer des entrepôts")}
                </Typography>

                {warehouses.map((warehouse, index) => (
                  <Grid container spacing={3} key={index} mb={2}>
                    <Grid item xs={12} sm={2.5}>
                      <SoftTypography variant="h6">{t("Nom")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="name"
                        value={warehouse.name}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={2.5}>
                      <SoftTypography variant="h6">{t("Ville")}</SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="city"
                        value={warehouse.city}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                      <SoftTypography variant="h6">
                        {t("Adresse")}
                      </SoftTypography>
                      <SoftInput
                        style={{ marginBottom: "1rem" }}
                        name="address"
                        value={warehouse.address}
                        onChange={(e) =>
                          handleWarehouseChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={10.5} sm={3}>
                      <SoftTypography variant="h6">
                        {t("Gestionnaire")}
                      </SoftTypography>
                      <MultiSelect
                        placeholder={t("Sélectionner un gestionnaire")}
                        options={warehousesList}
                        value={warehouse.managers}
                        onChange={(selectedOptions) =>
                          handleWarehouseChange(
                            index,
                            "managers",
                            selectedOptions
                          )
                        }
                      />
                      <SoftTypography
                        variant="caption"
                        color="info"
                        fontWeight="bold"
                        sx={{ cursor: "pointer" }}
                        onClick={() =>
                          navigate("/gestion-des-ventes/nouveau-manager")
                        }
                      >
                        + {t("Ajouter un gestionnaire")}
                      </SoftTypography>
                    </Grid>

                    <Grid item xs={1}>
                      <IconButton
                        color="error"
                        onClick={() => handleRemoveWarehouse(index)}
                        sx={{ mt: 3 }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                ))}
                <Box
                  display="flex"
                  sx={{
                    pt: { xs: 5 },
                    mt: { md: 2 },
                  }}
                >
                  <SoftButton
                    variant="outlined"
                    color="info"
                    onClick={handleAddWarehouse}
                  >
                    <AddIcon /> {t("Ajouter un entrepôt")}
                  </SoftButton>
                </Box>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <SoftButton
                  variant="contained"
                  color="info"
                  onClick={handleSubmit}
                  disabled={loading} // Disable button while loading
                >
                  {loading
                    ? t("En cours...")
                    : t("Enregistrer les modifications")}
                </SoftButton>
              </Box>
            </SoftBox>
          </Card>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default NewWarehouse;
