import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import FormField from "../FormField";
import { useTranslation } from "react-i18next";

function Pricing({ onChange, defaultValues, onValidation }) {
  const { t } = useTranslation();

  const [unitPrice, setUnitPrice] = useState(defaultValues?.unit_price);
  const [buyingPrice, setBuyingPrice] = useState(defaultValues?.buying_price);
  const [minStockQuantity, setMinStockQuantity] = useState(
    defaultValues?.product_min_stock_quantity
  );
  const [maxItemsToOrder, setMaxItemsToOrder] = useState(
    defaultValues?.max_order
  );
  const [itemsPerBox, setItemsPerBox] = useState(
    defaultValues?.box_items_quantity
  );
  const [isBox, setIsBox] = useState(defaultValues?.box || false);
  // State for error messages
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setUnitPrice(defaultValues?.unit_price);
    setBuyingPrice(defaultValues?.buying_price);
    setMinStockQuantity(defaultValues?.product_min_stock_quantity);
    setMaxItemsToOrder(defaultValues?.max_order);
    setItemsPerBox(defaultValues?.box_items_quantity);
    setIsBox(defaultValues?.box || false);
  }, [defaultValues]);

  useEffect(() => {
    onChange({
      unitPrice,
      buyingPrice,
      minStockQuantity,
      maxItemsToOrder,
      itemsPerBox,
      isBox,
    });
    // Trigger validation if onValidation prop is provided
    if (onValidation) {
      onValidation(validate());
    }
    // eslint-disable-next-line
  }, [
    unitPrice,
    buyingPrice,
    minStockQuantity,
    maxItemsToOrder,
    itemsPerBox,
    isBox,
    onChange,
    onValidation,
  ]);

  const handleInputChange = (field, value) => {
    switch (field) {
      case "unitPrice":
        setUnitPrice(value);
        break;
      case "buyingPrice":
        setBuyingPrice(value);
        break;
      case "minStockQuantity":
        setMinStockQuantity(value);
        break;
      case "maxItemsToOrder":
        setMaxItemsToOrder(value);
        break;
      case "itemsPerBox":
        setItemsPerBox(value);
        break;
      case "isBox":
        setIsBox(!isBox);
        break;
      default:
        break;
    }
  };

  // Validation function
  const validate = () => {
    const newErrors = {};

    if (!unitPrice || isNaN(unitPrice) || unitPrice <= 0) {
      newErrors.unitPrice = t(
        "Le prix unitaire est requis et doit être un nombre positif."
      );
    }

    if (!buyingPrice || isNaN(buyingPrice) || buyingPrice <= 0) {
      newErrors.buyingPrice = t(
        "Le prix d'achat est requis et doit être un nombre positif."
      );
    }

    if (!minStockQuantity || isNaN(minStockQuantity) || minStockQuantity <= 0) {
      newErrors.minStockQuantity = t(
        "La quantité minimale en stock est requise et doit être un nombre positif."
      );
    }

    if (!maxItemsToOrder || isNaN(maxItemsToOrder) || maxItemsToOrder <= 0) {
      newErrors.maxItemsToOrder = t(
        "Le nombre maximum d'articles à commander est requis et doit être un nombre positif."
      );
    }

    if (isBox && (!itemsPerBox || isNaN(itemsPerBox) || itemsPerBox <= 0)) {
      newErrors.itemsPerBox = t(
        "La quantité d'articles par boîte est requise et doit être un nombre positif."
      );
    }

    // Optional validation for the checkbox field
    if (typeof isBox !== "boolean" || (itemsPerBox > 0 && !isBox)) {
      newErrors.isBox = t("Veuillez spécifier si c'est une boîte.");
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  return (
    <SoftBox>
      <SoftTypography variant="h5">{t("Tarification")}</SoftTypography>
      <SoftBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label={t("Prix unitaire")}
              placeholder="99.00"
              value={unitPrice || defaultValues?.unit_price}
              onChange={(e) => handleInputChange("unitPrice", e.target.value)}
              error={!!errors.unitPrice}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.unitPrice}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="text"
              label={t("Prix d'achat")}
              placeholder="50.00"
              value={buyingPrice || defaultValues?.buying_price}
              onChange={(e) => handleInputChange("buyingPrice", e.target.value)}
              error={!!errors.buyingPrice}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.buyingPrice}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="number"
              label={t("Quantité minimale en stock")}
              placeholder="5"
              value={
                minStockQuantity || defaultValues?.product_min_stock_quantity
              }
              onChange={(e) =>
                handleInputChange("minStockQuantity", e.target.value)
              }
              error={!!errors.minStockQuantity}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.minStockQuantity}
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
      <SoftBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={4}>
            <FormField
              type="number"
              label={t("Nombre maximum d'articles à commander")}
              placeholder="100"
              value={maxItemsToOrder || defaultValues?.max_order}
              onChange={(e) =>
                handleInputChange("maxItemsToOrder", e.target.value)
              }
              error={!!errors.maxItemsToOrder}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.maxItemsToOrder}
            </SoftTypography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormField
              type="number"
              label={t("Quantité d'articles par boîte")}
              placeholder="10"
              value={itemsPerBox || defaultValues?.box_items_quantity}
              onChange={(e) => handleInputChange("itemsPerBox", e.target.value)}
              error={!!errors.itemsPerBox}
              helperText={errors.itemsPerBox}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.itemsPerBox}
            </SoftTypography>
          </Grid>
          <Grid item mt={5} ml={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={isBox}
                  onChange={() => handleInputChange("isBox")}
                />
              }
              label={t("Est-ce une boîte")}
            />
            <SoftTypography component="label" variant="caption" color="error">
              {errors.isBox}
            </SoftTypography>
          </Grid>
        </Grid>
      </SoftBox>
    </SoftBox>
  );
}

export default Pricing;
