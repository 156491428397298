import { useState, useEffect } from "react";
import {
  ArrowRight,
  QrCode,
  ShoppingBag,
  CreditCard,
  Truck,
  Receipt,
  Mail,
  ChevronDown,
  Sparkles,
  LucideShoppingCart,
  Phone,
  ArrowLeft,
} from "lucide-react";
import PageLayout from "examples/LayoutContainers/PageLayout";
import { cn } from "utils/cnUtils";
import "./index.css";
import { useTranslation } from "react-i18next";
import { Button } from "components/Button";
import { Card } from "components/Card";
import { useNavigate } from "react-router-dom";
import LanguageSwitcher from "components/LanguageSwitcher.js";

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);
  const [deviceType, setDeviceType] = useState("pc");

  // Determine device type on window resize
  useEffect(() => {
    const updateDeviceType = () => {
      const width = window.innerWidth;
      if (width < 640) {
        setDeviceType("mobile");
      } else if (width < 1024) {
        setDeviceType("tablet");
      } else {
        setDeviceType("pc");
      }
    };

    updateDeviceType(); // Initial check
    window.addEventListener("resize", updateDeviceType);
    return () => window.removeEventListener("resize", updateDeviceType);
  }, []);

  // Scroll to #contact if the URL contains #contact
  useEffect(() => {
    if (window.location.hash === "#contact") {
      const contactSection = document.getElementById("contact");
      if (contactSection) {
        setTimeout(() => {
          contactSection.scrollIntoView({ behavior: "smooth" });
        }, 100); // Adjust delay as needed
      }
    }
    // eslint-disable-next-line
  }, [window.location.hash]);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="homepage">
      <nav
        className={cn(
          "fixed top-0 w-full z-50 transition-all duration-300",
          scrolled
            ? "bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-b shadow-sm"
            : "bg-transparent"
        )}
      >
        <div className="container mx-auto px-4">
          <div className="flex h-16 sm:h-20 items-center justify-between">
            <div className="flex items-center space-x-3 sm:space-x-4">
              <div className="relative">
                <LucideShoppingCart className="h-6 w-6 sm:h-8 sm:w-8 text-primary animate" />
                <Sparkles className="h-3 w-3 sm:h-4 sm:w-4 text-primary absolute -top-1 -right-1" />
              </div>
              <span className="text-xl sm:text-2xl font-bold bg-primary to-primary/70 bg-clip-text text-transparent">
                {t("Livrili")}
              </span>
            </div>

            {deviceType === "pc" && (
              <div className="hidden md:flex items-center">
                <div className="mx-5 md:flex items-center">
                  <a
                    href="#home"
                    className="text-sm font-medium hover:text-primary transition-all hover:scale-105 mx-3"
                  >
                    {t("Accueil")}
                  </a>
                  <a
                    href="#features"
                    className="text-sm font-medium hover:text-primary transition-all hover:scale-105 mx-3"
                  >
                    {t("Our Features")}
                  </a>
                  <a
                    href="#contact"
                    className="text-sm font-medium hover:text-primary transition-all hover:scale-105 mx-3"
                  >
                    {t("Contact")}
                  </a>
                  <LanguageSwitcher color="inherit" title />
                </div>
                <Button
                  size="sm"
                  className="shadow-lg hover:shadow-primary/25 transition-all hover:-translate-y-0.5 bg-primary"
                  onClick={() => navigate("/authentication/connexion")}
                >
                  {t("login")}
                  {i18n.language === "ar" ? (
                    <ArrowLeft className="ml-2 h-4 w-4" />
                  ) : (
                    <ArrowRight className="ml-2 h-4 w-4" />
                  )}
                </Button>
              </div>
            )}

            {deviceType !== "pc" && (
              <Button
                variant="ghost"
                size="sm"
                className="md:hidden"
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <ChevronDown
                  className={cn(
                    "h-4 w-4 transition-transform duration-300",
                    isMenuOpen && "rotate-180"
                  )}
                />
              </Button>
            )}
          </div>
        </div>
      </nav>

      {/* Mobile Menu */}
      {deviceType !== "pc" && isMenuOpen && (
        <div className="fixed inset-x-0 top-16 sm:top-20 bg-background/95 backdrop-blur supports-[backdrop-filter]:bg-background/60 border-b md:hidden z-40 transition-all duration-300">
          <div className="container mx-auto px-4 py-4 sm:py-6 space-y-4 sm:space-y-6">
            <a
              href="#home"
              className="block text-sm font-medium hover:text-primary transition-colors"
            >
              {t("Accueil")}
            </a>
            <a
              href="#features"
              className="block text-sm font-medium hover:text-primary transition-colors"
            >
              {t("Our Features")}
            </a>
            <a
              href="#contact"
              className="block text-sm font-medium hover:text-primary transition-colors"
            >
              {t("Contact")}
            </a>
            <Button
              size="sm"
              className="w-full shadow-lg hover:shadow-primary/25 bg-gradient-to-r from-primary to-primary/70"
              onClick={() => navigate("/authentication/connexion")}
            >
              {t("login")}
              {i18n.language === "ar" ? (
                <ArrowLeft className="ml-2 h-4 w-4" />
              ) : (
                <ArrowRight className="ml-2 h-4 w-4" />
              )}
            </Button>
          </div>
        </div>
      )}
      <PageLayout>
        {/* Hero Section */}
        <section
          id="home"
          className="min-h-[calc(100vh-4rem)] sm:min-h-screen pt-16 sm:pt-20 flex items-center"
        >
          <div className="container mx-auto px-4">
            <div className="grid lg:grid-cols-2 gap-8 lg:gap-[22em] items-center justify-between">
              <div className="text-center lg:text-left space-y-6 sm:space-y-8">
                <div
                  className={`flex animate-fade-in ${
                    i18n.language === "ar" ? "text-right" : "text-left"
                  } `}
                >
                  <span className="inline-flex items-center px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-primary/10 text-primary text-xs sm:text-sm font-medium">
                    <Sparkles className="h-3 w-3 sm:h-4 sm:w-4 mx-1.5 sm:mx-2" />
                    {t("Revolutionizing Grocery Shopping")}
                  </span>
                </div>
                <h1
                  className={`text-4xl lg:w-[8em] sm:text-5xl md:text-6xl lg:text-7xl font-bold leading-tight animate-slide-up ${
                    i18n.language === "ar" ? "text-right" : "text-left"
                  } `}
                >
                  {t("Welcome to")}
                  <span className="block mt-2 bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
                    {t("Livrili")}
                  </span>
                </h1>
                <p
                  className={`text-lg sm:text-xl text-muted-foreground max-w-xl mx-auto lg:mx-0 animate-fade-in ${
                    i18n.language === "ar" ? "text-right" : "text-left"
                  } `}
                >
                  {t("landingPageText")}
                </p>
                <div className="flex flex-col sm:flex-row gap-6 sm:gap-8 justify-center lg:justify-start items-center animate-slide-up">
                  <div className="relative group">
                    <div className="absolute -inset-1 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-1000"></div>
                    <img
                      src="https://api.qrserver.com/v1/create-qr-code/?size=150x150&data=https://play.google.com/store/apps/details?id=com.kamioun.kamioun"
                      alt="QR Code"
                      className="relative w-32 sm:w-36 h-32 sm:h-36"
                    />
                  </div>
                  <a
                    href="https://play.google.com/store/apps/details?id=com.kamioun.kamioun"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="transform hover:scale-105 transition-transform"
                  >
                    <img
                      src="https://res.cloudinary.com/dcrzf4pw1/image/upload/v1724946312/google-play_cozg6k.png"
                      alt="Get it on Google Play"
                      className="h-[220px] hover:opacity-90 transition-opacity"
                    />
                  </a>
                </div>
              </div>
              <div className="relative group perspective max-w-md mx-auto lg:mx-0">
                <div className="absolute rounded-[2rem] transition duration-1000"></div>
                <div className="relative transform group-hover:rotate-y-6 transition-transform duration-700">
                  <img
                    src="https://res.cloudinary.com/dcrzf4pw1/image/upload/v1737969176/Livrili/Images/Group_65_oaigsj.png"
                    alt="Grocery Shopping App"
                    className="relative rounded-[1.75rem] w-full h-auto object-cover transform group-hover:scale-[1.02] transition-transform duration-500"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section
          id="features"
          className="py-16 sm:py-24 md:py-32 relative overflow-hidden"
        >
          <div className="absolute inset-0 bg-gradient-to-b from-background via-primary/5 to-background"></div>
          <div className="container mx-auto px-4 relative">
            <div className="text-center mb-12 sm:mb-16 md:mb-20">
              <span className="inline-flex items-center px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-primary/10 text-primary text-xs sm:text-sm font-medium mb-4 sm:mb-6">
                <Sparkles className="h-3 w-3 sm:h-4 sm:w-4 mx-1.5 sm:mx-2" />
                {t("Why Choose Livrili")}
              </span>
              <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
                {t("Our Features")}
              </h2>
              <p className="text-lg sm:text-xl text-muted-foreground max-w-2xl mx-auto">
                {t(
                  "Discover how Livrili makes your grocery shopping experience better with our innovative features."
                )}
              </p>
            </div>

            <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
              {[
                {
                  icon: <QrCode className="h-6 w-6 sm:h-8 sm:w-8" />,
                  title: t("QR Code Scanning"),
                  description: t(
                    "Quickly scan products for instant information"
                  ),
                },
                {
                  icon: <ShoppingBag className="h-6 w-6 sm:h-8 sm:w-8" />,
                  title: t("Easy Shopping"),
                  description: t("Add items to your cart with a single tap"),
                },
                {
                  icon: <CreditCard className="h-6 w-6 sm:h-8 sm:w-8" />,
                  title: t("Secure Payment"),
                  description: t(
                    "Multiple payment options for your convenience"
                  ),
                },
                {
                  icon: <Truck className="h-6 w-6 sm:h-8 sm:w-8" />,
                  title: t("Order Tracking"),
                  description: t("Real-time updates on your delivery status"),
                },
                {
                  icon: <Receipt className="h-6 w-6 sm:h-8 sm:w-8" />,
                  title: t("Digital Invoices"),
                  description: t(
                    "Easily view and download your invoices online"
                  ),
                },
                {
                  icon: (
                    <LucideShoppingCart className="h-6 w-6 sm:h-8 sm:w-8" />
                  ),
                  title: t("Smart Cart"),
                  description: t("Intelligent shopping list management"),
                },
              ].map((feature, index) => (
                <Card
                  key={index}
                  className="group p-6 sm:p-8 hover:shadow-xl transition-all duration-300 hover:-translate-y-1 bg-background/50 backdrop-blur-sm border border-primary/10 hover:border-primary/30"
                >
                  <div className="text-primary mb-4 sm:mb-6 transform group-hover:scale-110 transition-transform duration-300 relative">
                    {feature.icon}
                    <div className="absolute inset-0 bg-primary/10 rounded-full blur opacity-0 group-hover:opacity-100 transition-opacity duration-300"></div>
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold mb-2 sm:mb-3 group-hover:text-primary transition-colors">
                    {feature.title}
                  </h3>
                  <p className="text-sm sm:text-base text-muted-foreground group-hover:text-foreground/80 transition-colors">
                    {feature.description}
                  </p>
                </Card>
              ))}
            </div>
          </div>
        </section>

        {/* Contact Section */}
        <section id="contact" className="py-16 sm:py-24 md:py-32 relative">
          <div className="absolute inset-0 bg-gradient-to-t from-primary/5 to-background"></div>
          <div className="container mx-auto px-4 relative">
            <div className="max-w-4xl mx-auto">
              <div className="text-center mb-12 sm:mb-16">
                <span className="inline-flex items-center px-3 sm:px-4 py-1.5 sm:py-2 rounded-full bg-primary/10 text-primary text-xs sm:text-sm font-medium mb-4 sm:mb-6">
                  <Mail className="h-3 w-3 sm:h-4 sm:w-4 mx-1.5 sm:mx-2" />
                  {t("Get in Touch")}
                </span>
                <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
                  {t("Nous Contacter")}
                </h2>
                <p className="text-lg sm:text-xl text-muted-foreground">
                  {t("Have questions? We're here to help you.")}
                </p>
              </div>
              <div className="grid sm:grid-cols-2 gap-6 sm:gap-8">
                <a
                  href="mailto:rejeb.chaalia@gmail.com"
                  className="group relative perspective"
                >
                  <div className="absolute -inset-1 bg-gradient-to-r from-primary to-primary/50 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-1000"></div>
                  <div
                    dir="ltr"
                    className="relative flex items-center justify-center space-x-3 sm:space-x-4 p-6 sm:p-8 bg-background rounded-lg border border-primary/10 hover:border-primary/30 transition-all transform group-hover:rotate-y-6 duration-500"
                  >
                    <Mail className="h-5 w-5 sm:h-6 sm:w-6 text-primary group-hover:scale-110 transition-transform" />
                    <span className="text-base sm:text-lg group-hover:text-primary transition-colors">
                      rejeb.chaalia@gmail.com
                    </span>
                  </div>
                </a>
                <a
                  href="tel:+21652187320"
                  className="group relative perspective"
                >
                  <div className="absolute -inset-1 bg-gradient-to-r from-primary to-primary/50 rounded-lg blur opacity-25 group-hover:opacity-75 transition duration-1000"></div>
                  <div
                    dir="ltr"
                    className="relative flex items-center justify-center space-x-3 sm:space-x-4 p-6 sm:p-8 bg-background rounded-lg border border-primary/10 hover:border-primary/30 transition-all transform group-hover:rotate-y-6 duration-500"
                  >
                    <Phone className="h-5 w-5 sm:h-6 sm:w-6 text-primary group-hover:scale-110 transition-transform" />
                    <span className="text-base sm:text-lg group-hover:text-primary transition-colors">
                      +216 52 187 320
                    </span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="bg-primary/5 border-t border-primary/10">
          <div className="container mx-auto px-4 py-12 sm:py-16">
            <div className="grid sm:grid-cols-3 gap-8 sm:gap-12 items-center">
              <div className="text-center sm:text-left">
                <div className="flex items-center justify-center sm:justify-start space-x-3 mb-3 sm:mb-4">
                  <LucideShoppingCart className="h-6 w-6 sm:h-8 sm:w-8 text-primary" />
                  <span className="text-xl sm:text-2xl font-bold bg-gradient-to-r from-primary to-primary/70 bg-clip-text text-transparent">
                    {t("Livrili")}
                  </span>
                </div>
                <p
                  className={`text-sm sm:text-base text-muted-foreground ${
                    i18n.language === "ar" ? "text-right" : "text-left"
                  }`}
                >
                  {t("Making grocery shopping simple and efficient.")}
                </p>
              </div>
              <div className="flex justify-center">
                <a
                  href="#home"
                  className="text-sm hover:text-primary transition-colors mx-3"
                >
                  {t("Accueil")}
                </a>
                <a
                  href="#features"
                  className="text-sm hover:text-primary transition-colors mx-3"
                >
                  {t("Our Features")}
                </a>
                <a
                  href="#contact"
                  className="text-sm hover:text-primary transition-colors mx-3"
                >
                  {t("Contact")}
                </a>
              </div>
              <div className="text-center sm:text-right">
                <p className="text-xs sm:text-sm text-muted-foreground">
                  {t("All rights reserved")}. © {new Date().getFullYear()}{" "}
                  {t("Livrili")}.
                </p>
              </div>
            </div>
          </div>
        </footer>
      </PageLayout>
    </div>
  );
};

export default HomePage;
