import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import { useParams } from "react-router-dom";
import StockApi from "api/stock";
import { CircularProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

function TransactionHistory() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.log("No token found in localStorage.");
        return;
      }
      const response = await StockApi.GetTransactionHistoryByProduct(id, token);
      if (response.data) {
        setData(response.data);
      } else {
        console.log("No data found.");
      }
    } catch (error) {
      console.log(error.response?.data?.msg || error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [id]);

  if (loading)
    return (
      <Card>
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          p={35}
        >
          <CircularProgress sx={{ color: "#1F51FF" }} />
        </SoftBox>
      </Card>
    );

  const {
    res: transactions,
    quantity_in_stock,
    value_in_stock,
    min_stock,
  } = data;

  const totalEntreeQuantite = transactions?.reduce(
    (sum, row) => sum + row.entered_quantity,
    0
  );
  const totalSortieQuantite = transactions?.reduce(
    (sum, row) => sum + row.sorted_quantity,
    0
  );
  const totalValeurEntree = transactions?.reduce(
    (sum, row) => sum + row.entered_value,
    0
  );
  const totalValeurSortie = transactions?.reduce(
    (sum, row) => sum + row.sorted_value,
    0
  );
  const totalTotal = transactions?.reduce((sum, row) => sum + row.total, 0);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        <SoftBox my={3}>
          <Grid container spacing={3} justifyContent="center">
            <Grid item xs={12} lg={10}>
              <Card>
                <SoftBox p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                      >
                        <SoftTypography variant="h4" fontWeight="medium">
                          {t("Stock Article")}
                        </SoftTypography>
                        <SoftTypography variant="h6" fontWeight="medium" mt={1}>
                          {new Date().toLocaleDateString()}
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <SoftBox
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        textAlign="center"
                      >
                        <SoftTypography variant="h6" fontWeight="medium">
                          {t("Stock Min")}: {min_stock || ""}
                        </SoftTypography>
                        <SoftTypography variant="h6" fontWeight="medium">
                          {t("Quantité en stock")}: {quantity_in_stock}
                        </SoftTypography>
                        <SoftTypography variant="h6" fontWeight="medium">
                          {t("Valeur en stock")}: {value_in_stock}
                        </SoftTypography>
                      </SoftBox>
                    </Grid>
                  </Grid>
                </SoftBox>
                <SoftBox p={3} sx={{ overflowX: "auto" }}>
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      minWidth: "600px",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Date
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Type Operation")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Description
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("P.U")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Entrée Quantité")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Sortie Quantité")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Valeur Entrée")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          {t("Valeur Sortie")}
                        </th>
                        <th
                          style={{ border: "1px solid #ddd", padding: "8px" }}
                        >
                          Total
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {transactions?.map((row, index) => (
                        <tr key={index}>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.date.split(",")[0]}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.operation_type}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.description}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.pu}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.entered_quantity}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.sorted_quantity}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.entered_value}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.sorted_value}
                          </td>
                          <td
                            style={{
                              border: "1px solid #ddd",
                              padding: "8px",
                              textAlign: "center",
                            }}
                          >
                            {row.total}
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td
                          colSpan={4}
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          Total Mouvements
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalEntreeQuantite}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalSortieQuantite}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalValeurEntree}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalValeurSortie}
                        </td>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                            fontWeight: "bold",
                          }}
                        >
                          {totalTotal}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </SoftBox>
              </Card>
            </Grid>
          </Grid>
        </SoftBox>
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default TransactionHistory;
