// @mui material components
import Grid from "@mui/material/Grid"; // Grid layout system from MUI

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox"; // Custom box component for layout
import SoftTypography from "components/SoftTypography"; // Custom typography component
import SoftButton from "components/SoftButton"; // Custom button component

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout"; // Layout container component
import DashboardNavbar from "examples/Navbars/DashboardNavbar"; // Navbar component
import Footer from "examples/Footer"; // Footer component

// EditProduct page components
import ProductImage from "./components/ProductImage"; // Component for displaying and updating product image
import ProductInfo from "./components/ProductInfo"; // Component for displaying and updating product information
import Pricing from "./components/Pricing"; // Component for displaying and updating product pricing
import { useState, useEffect } from "react"; // React hooks for managing state and side effects
import ProductsApi from "api/products"; // API functions for interacting with product data
import { useParams, useNavigate } from "react-router-dom"; // Hooks for routing
import CircularProgress from "@mui/material/CircularProgress"; // Circular progress indicator from MUI
import { useTranslation } from "react-i18next";

function EditProduct() {
  const { t } = useTranslation();

  const { productId } = useParams(); // Get the product ID from URL parameters
  const navigate = useNavigate(); // Hook for programmatic navigation
  const [initialInfo, setInitialtInfo] = useState({}); // State for storing initial product data
  const [productInfo, setProductInfo] = useState({}); // State for storing product information
  const [mediaInfo, setMediaInfo] = useState({ product_image: "" }); // State for storing media information
  const [pricingInfo, setPricingInfo] = useState({}); // State for storing pricing information
  const [loading, setLoading] = useState(false); // State for managing loading state
  const [isModified, setIsModified] = useState(false); // Track if fields are modified
  const [loadingData, setLoadingData] = useState(false);

  const [isFormValid, setIsFormValid] = useState(false);

  const handleValidation = (isValid) => {
    setIsFormValid(isValid); // This will update the validation state in the parent
  };
  useEffect(() => {
    const token = localStorage.getItem("token"); // Retrieve token from local storage
    if (!token) {
      console.error("No token found in localStorage."); // Handle case where token is missing
      return;
    }

    if (productId) {
      setLoadingData(true);
      ProductsApi.GetProductById(productId, token) // Fetch product data by ID
        .then((response) => {
          const data = response.data;
          setInitialtInfo(data); // Set initial product data
          setProductInfo({
            productName: data.product_name,
            description: data.description,
            status: { value: data.status },
            category: { value: data.category_id },
            itemsPerBox: data.box_items_quantity,
            referenceCode: data.reference_code,
            createDate: data.creation_date,
            updatedAtDate: data.updated_at,
            isTaxed: data.is_taxed,
          });
          setMediaInfo({ product_image: data.product_image });
          setPricingInfo({
            unitPrice: data.unit_price,
            buyingPrice: data.buying_price,
            maxItemsToOrder: data.max_order,
            isBox: data.box,
            minStockQuantity: data.product_min_stock_quantity,
          });
          setLoadingData(false);
        })
        .catch((error) => {
          console.error("Error fetching product data:", error); // Handle API errors
          setLoadingData(false);
        });
    }
  }, [productId]); // Effect runs when productId changes

  const detectChanges = () => {
    const hasChanges =
      productInfo.productName !== initialInfo.product_name ||
      productInfo.description !== initialInfo.description ||
      productInfo.status?.value !== initialInfo.status ||
      productInfo.category?.value !== initialInfo.category_id ||
      mediaInfo.product_image !== initialInfo.product_image ||
      productInfo.referenceCode !== initialInfo.reference_code ||
      productInfo.createDate !== initialInfo.creation_date ||
      productInfo.updatedAtDate !== initialInfo.updated_at ||
      productInfo.isTaxed !== initialInfo.is_taxed ||
      productInfo.supplier !== initialInfo.suppliers_details ||
      pricingInfo.unitPrice !== initialInfo.unit_price ||
      pricingInfo.buyingPrice !== initialInfo.buying_price ||
      pricingInfo.maxItemsToOrder !== initialInfo.max_order ||
      pricingInfo.isBox !== initialInfo.box ||
      pricingInfo.minStockQuantity !== initialInfo.product_min_stock_quantity ||
      pricingInfo.itemsPerBox !== initialInfo.box_items_quantity;

    setIsModified(hasChanges);
  };

  const handleProductInfoChange = (newInfo) => {
    setProductInfo({ ...productInfo, ...newInfo }); // Update product info state
    detectChanges(); // Check for changes
  };

  const handleMediaChange = (newInfo) => {
    setMediaInfo({ ...mediaInfo, ...newInfo }); // Update media info state
    detectChanges(); // Check for changes
  };

  const handlePricingChange = (newInfo) => {
    setPricingInfo({ ...pricingInfo, ...newInfo }); // Update pricing info state
    detectChanges(); // Check for changes
  };

  const getEditedValues = (initial, current) => {
    const editedValues = {};
    for (const key in current) {
      if (current[key] !== initial[key]) {
        editedValues[key] = current[key]; // Determine which values have been edited
      }
    }
    return editedValues;
  };

  const handleSave = () => {
    if (!isFormValid) {
      console.log("Form is not valid, please complete the required fields.");
      return;
    }
    const token = localStorage.getItem("token"); // Retrieve token from local storage
    if (!token) {
      console.error("No token found in localStorage."); // Handle case where token is missing
      return;
    }

    setLoading(true); // Set loading state to true

    const editedValues = getEditedValues(initialInfo, {
      product_name: productInfo.productName,
      description: productInfo.description,
      status: productInfo.status.value,
      category_id: productInfo.category.value,
      product_image: mediaInfo.product_image,
      reference_code: productInfo.referenceCode,
      creation_date: productInfo.createDate,
      updated_at: productInfo.updatedAtDate,
      is_taxed: productInfo.isTaxed,
      unit_price: pricingInfo.unitPrice,
      buying_price: pricingInfo.buyingPrice,
      max_order: pricingInfo.maxItemsToOrder,
      box_items_quantity: pricingInfo.itemsPerBox,
      box: pricingInfo.isBox,
      product_min_stock_quantity: pricingInfo.minStockQuantity,
      suppliers: productInfo.supplier.map((supplier) => supplier.id), // Extract values
    });

    const payload = Object.keys(editedValues).reduce((acc, key) => {
      if (
        editedValues[key] !== null &&
        editedValues[key] !== undefined &&
        editedValues[key] !== "" &&
        editedValues[key] !== initialInfo[key]
      ) {
        acc[key] = editedValues[key]; // Prepare payload with edited values
      }
      return acc;
    }, {});

    ProductsApi.UpdateProduct(payload, productId, token) // Send update request
      .then((response) => {
        navigate("/gestion-de-stock/liste-des-articles"); // Navigate to product list page
      })
      .catch((error) => {
        console.error(error.response?.data?.msg || error.message); // Handle API errors
      })
      .finally(() => {
        setLoading(false); // Reset loading state
      });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        minHeight="85vh"
      >
        {loadingData ? (
          <SoftBox
            display="flex"
            justifyContent="center"
            alignItems="center"
            p={35}
          >
            <CircularProgress sx={{ color: "#1F51FF" }} />
          </SoftBox>
        ) : (
          <SoftBox my={3}>
            <SoftBox mb={3}>
              <Grid container spacing={3} alignItems="center">
                <Grid item xs={12} lg={6}>
                  <SoftTypography variant="h4" fontWeight="medium">
                    {t("Apportez les modifications ci-dessous")}
                  </SoftTypography>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <SoftBox display="flex" justifyContent="flex-end">
                    <SoftButton
                      variant="gradient"
                      color="info"
                      onClick={handleSave}
                      disabled={!isModified || loading}
                    >
                      {loading ? (
                        <CircularProgress size={24} color="inherit" /> // Show loading spinner
                      ) : (
                        `${t("Enregistrer")}` // Save button text
                      )}
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={4}>
                <ProductImage
                  onChange={handleMediaChange}
                  defaultImage={mediaInfo.product_image}
                />
              </Grid>
              <Grid item xs={12} lg={8}>
                <ProductInfo
                  onChange={handleProductInfoChange}
                  defaultValues={initialInfo}
                  onValidation={handleValidation}
                />
                <Grid item xs={12} lg={8} mt={5}>
                  <Pricing
                    onChange={handlePricingChange}
                    defaultValues={initialInfo}
                    onValidation={handleValidation}
                  />
                </Grid>
              </Grid>
            </Grid>
          </SoftBox>
        )}
        <Footer />
      </SoftBox>
    </DashboardLayout>
  );
}

export default EditProduct;
