import axios from "./index";

// StockApi class handles operations related to stock management
class StockApi {
  /**
   * Fetches the current stock situation.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the current stock situation.
   */
  static GetStock = (token, outOfStock) => {
    return axios.get(
      `${base}/stock_situation?out_of_stock=${outOfStock}`,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
  };

  static GetStockOfAllWarehouses = (token) => {
    return axios.get("warehouse/all_warehouse_stocks", {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Fetches the transaction history of a specific product.
   * @param {string} productId - The ID of the product to retrieve the transaction history for.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response containing the transaction history of the specified product.
   */
  static GetTransactionHistoryByProduct = (productId, token) => {
    return axios.get(`product/product_history/${productId}`, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Sets the initial stock for the system.
   * @param {object} data - The data to set the initial stock.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response confirming the initial stock setup.
   */
  static SetInitialStock = (data, token) => {
    return axios.post(`/stock/initial_stock`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };

  /**
   * Increases the stock for products.
   * @param {object} data - The data to increase the stock.
   * @param {string} token - The authentication token for the user.
   * @returns {Promise} - The response confirming the stock increase.
   */
  static IncreaseStock = (data, token) => {
    return axios.post(`/stock/actual_stock`, data, {
      headers: { Authorization: `Bearer ${token}` },
    });
  };
}

// Base URL for the stock-related API
let base = "stock";

export default StockApi;
