import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import CircularProgress from "@mui/material/CircularProgress";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import InvoicesApi from "api/invoices";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import html2pdf from "html2pdf.js";
import CompanyApi from "api/companyInfo";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";
import numberToWords from "utils/numberToWords";
import numberToWordsArabic from "utils/numberToWordsArabic";
import numberToWordsEnglish from "utils/numberToWordEnglish";

function DeliveryNoteDetails() {
  const { id } = useParams();
  const [deliveryNoteDetails, setDeliveryNoteDetails] = useState(null);
  const [companyInfos, setCompanyInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { t, i18n } = useTranslation();

  const manager = localStorage.getItem("manager");

  const order = deliveryNoteDetails?.order;

  useEffect(() => {
    const fetchDeliveryNoteDetails = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError(new Error("Token not found."));
        setLoading(false);
        return;
      }

      try {
        const response = await InvoicesApi.GetDeliveryNotesById(token, id);
        setDeliveryNoteDetails(response.data);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    const fetchCompanyInfos = async () => {
      const token = localStorage.getItem("token");

      if (!token) {
        setError(new Error("Token not found."));
        setLoading(false);
        return;
      }

      try {
        const response = await CompanyApi.GetCompanyInfos(token);
        setCompanyInfo(response.data.results[0]);
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };
    fetchDeliveryNoteDetails();
    fetchCompanyInfos();
  }, [id]);

  if (loading)
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress sx={{ color: "#1F51FF" }} />
      </SoftBox>
    );

  if (error)
    return (
      <SoftBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <SoftTypography variant="body1" color="error">
          {t("Erreur")}: {error.message}
        </SoftTypography>
      </SoftBox>
    );

  // eslint-disable-next-line
  const invoiceDate =
    new Date(deliveryNoteDetails?.date_created).toLocaleDateString() || "N/A";

  const downloadInvoice = (name) => {
    const element = document.getElementById("invoice-content");

    const options = {
      margin: [0.2, 0.2, 0.2, 0.2],
      filename: `${name}.pdf`,
      image: { type: "jpeg", quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      pagebreak: { mode: "avoid-all" },
    };

    // Apply RTL styles if the language is Arabic
    if (i18n.language === "ar") {
      element.style.direction = "rtl";
      element.style.textAlign = "ltr";
    }

    html2pdf().from(element).set(options).save();
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <SoftBox>
        <Grid container justifyContent="center">
          <Grid item xs={12} xl={10}>
            <Card id="invoice-content" style={{ fontFamily: "sans-serif" }}>
              {/* Header */}
              <SoftBox px={3} mt={3}>
                <Grid container justifyContent="space-between">
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    flexDirection="column"
                  >
                    <SoftTypography variant="h6" fontWeight="medium">
                      {companyInfos?.company_name}
                    </SoftTypography>
                    <SoftBox display="flex" flexDirection="column">
                      <SoftTypography variant="caption" fontWeight="medium">
                        {companyInfos?.address}
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="medium">
                        {t("Téléphone")}: {companyInfos?.contact_phone}
                      </SoftTypography>
                      <SoftTypography variant="caption" fontWeight="medium">
                        {t("Email")}: {companyInfos?.contact_email}
                      </SoftTypography>
                    </SoftBox>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    container
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    columnGap={1}
                    mt={1}
                  >
                    <SoftTypography variant="subtitle2" fontWeight="medium">
                      {t("Bon de livraison")}:
                    </SoftTypography>
                    <SoftTypography variant="subtitle2" fontWeight="medium">
                      {deliveryNoteDetails?.number || ""}
                    </SoftTypography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-end"
                    mt={2}
                  >
                    <SoftTypography variant="caption" fontWeight="medium">
                      {t("Numéro fiscal")}: {companyInfos?.code_tva}
                    </SoftTypography>
                    <SoftTypography variant="caption" fontWeight="medium">
                      {t("Registre de commerce")}:{" "}
                      {companyInfos?.business_registration_number}
                    </SoftTypography>
                    <SoftTypography variant="caption" fontWeight="medium">
                      {t("Banque")}: {companyInfos?.bank_id_statement}
                    </SoftTypography>
                  </Grid>
                </Grid>
              </SoftBox>

              <SoftBox display="flex" width="100%" px={3} pt={3}>
                <SoftBox
                  sx={{
                    width: "100%",
                    borderTop: "2px solid black",
                    borderLeft: "2px solid black",
                    borderRight: "2px solid black",
                    borderBottom: "1px solid black",
                    borderRadius: 2,
                    padding: 2,
                  }}
                >
                  <Grid container>
                    <Grid item xs={6} display="flex" gap={1}>
                      <Typography variant="subtitle2">
                        {t("Sous la responsabilité de M.")}
                      </Typography>
                      <Typography variant="subtitle2">
                        {deliveryNoteDetails?.client}
                      </Typography>
                      <Typography variant="subtitle2">
                        {deliveryNoteDetails?.order?.customer}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      display="flex"
                      gap={1}
                      justifyContent="flex-end"
                    >
                      <Typography variant="subtitle2">
                        {t("Utilisateur")}:
                      </Typography>
                      <Typography variant="subtitle2">{manager}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={6} display="flex" gap={2}>
                      <Typography variant="subtitle2">
                        {t("Adresse")}:
                      </Typography>
                      <Typography variant="subtitle2">
                        {deliveryNoteDetails?.address_detail}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" gap={2}>
                      <Typography variant="subtitle2">
                        {companyInfos?.city}, {t("le")} {invoiceDate}
                      </Typography>
                      <Typography variant="subtitle2">
                        {t("à")} 13:47
                      </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" mx={12} mt={1}>
                      <Typography variant="subtitle2">
                        {t("Non soumis à l'ajout")}
                      </Typography>
                    </Grid>
                    <Grid item xs={6} display="flex" mx={12}>
                      <Typography fontSize={15} fontWeight="medium">
                        {deliveryNoteDetails?.order?.mat_fiscale}
                      </Typography>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
              {/* Main content */}
              <SoftBox px={3}>
                <SoftBox
                  sx={{
                    border: "2px solid black",
                    borderRadius: 2,
                    width: "100%",
                    overflow: "hidden",
                    minHeight: "32vh",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <Table
                    sx={{
                      minWidth: "32rem",
                      borderCollapse: "collapse",
                      flexGrow: 1,
                      height: "100%",
                    }}
                  >
                    <SoftBox
                      component="thead"
                      sx={{
                        width: "100%",
                        borderBottom: "2px solid black",
                      }}
                    >
                      <TableRow sx={{ width: "100%", padding: 2 }}>
                        {/* Table Headers */}
                        <SoftBox
                          component="th"
                          py={1}
                          textAlign="center"
                          sx={{ borderBottom: "2px solid black" }}
                        >
                          <SoftTypography
                            variant="subtitle2"
                            fontWeight="medium"
                          >
                            {t("Code Article")}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="th"
                          py={1}
                          textAlign="center"
                          sx={{
                            borderBottom: "2px solid black",
                            borderLeft: "2px solid black",
                            borderRight: "2px solid black",
                          }}
                        >
                          <SoftTypography
                            variant="subtitle2"
                            fontWeight="medium"
                          >
                            {t("Designation")}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="th"
                          py={1}
                          textAlign="center"
                          sx={{
                            borderBottom: "2px solid black",
                            borderRight:
                              i18n.language === "ar" ? 0 : "2px solid black",
                            borderLeft:
                              i18n.language === "ar" ? "2px solid black" : 0,
                          }}
                        >
                          <SoftTypography
                            variant="subtitle2"
                            fontWeight="medium"
                          >
                            {t("Qté")}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="th"
                          py={1}
                          textAlign="center"
                          sx={{
                            borderBottom: "2px solid black",
                            borderRight:
                              i18n.language === "ar" ? 0 : "2px solid black",
                            borderLeft:
                              i18n.language === "ar" ? "2px solid black" : 0,
                          }}
                        >
                          <SoftTypography
                            variant="subtitle2"
                            fontWeight="medium"
                          >
                            {t("Prix unitaire")}
                          </SoftTypography>
                        </SoftBox>
                        <SoftBox
                          component="th"
                          py={1}
                          textAlign="center"
                          sx={{
                            borderBottom: "2px solid black",
                          }}
                        >
                          <SoftTypography
                            variant="subtitle2"
                            fontWeight="medium"
                          >
                            {t("Prix total")}
                          </SoftTypography>
                        </SoftBox>
                      </TableRow>
                    </SoftBox>

                    <TableBody sx={{ height: "100%" }}>
                      {/* Map through invoice details to display data rows */}
                      {order?.order_lines.map((line, lineIndex) => (
                        <TableRow key={`${order?.id}-${lineIndex}`}>
                          <SoftBox component="td" textAlign="center">
                            <SoftTypography variant="subtitle2">
                              {line?.product_id}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign={
                              i18n.language === "ar" ? "right" : "left"
                            }
                            px={1}
                            borderLeft="2px solid black"
                            borderRight="2px solid black"
                          >
                            <SoftTypography variant="subtitle2">
                              {line?.product_name}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="center"
                            px={2}
                            borderLeft={
                              i18n.language === "ar" ? "2px solid black" : 0
                            }
                            borderRight={
                              i18n.language === "ar" ? 0 : "2px solid black"
                            }
                          >
                            <SoftTypography variant="subtitle2">
                              {line?.quantity || 0}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="center"
                            borderLeft={
                              i18n.language === "ar" ? "2px solid black" : 0
                            }
                            borderRight={
                              i18n.language === "ar" ? 0 : "2px solid black"
                            }
                          >
                            <SoftTypography variant="subtitle2">
                              {line?.unit_price
                                ? Number(line?.unit_price).toFixed(3)
                                : "0.00"}
                            </SoftTypography>
                          </SoftBox>
                          <SoftBox component="td" textAlign="center">
                            <SoftTypography variant="subtitle2">
                              {line?.total_price
                                ? Number(line?.total_price).toFixed(3)
                                : "0.00"}
                            </SoftTypography>
                          </SoftBox>
                        </TableRow>
                      ))}

                      {/* Generate empty rows to fill the remaining table space */}
                      {[...Array(5)].map((_, index) => (
                        <TableRow key={`empty-${index}`}>
                          <SoftBox component="td" textAlign="center">
                            &nbsp;
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign={
                              i18n.language === "ar" ? "right" : "left"
                            }
                            px={1}
                            sx={{
                              borderLeft: "2px solid black",
                              borderRight: "2px solid black",
                            }}
                          >
                            &nbsp;
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="center"
                            px={2}
                            sx={{
                              borderRight:
                                i18n.language === "ar" ? 0 : "2px solid black",
                              borderLeft:
                                i18n.language === "ar" ? "2px solid black" : 0,
                            }}
                          >
                            &nbsp;
                          </SoftBox>
                          <SoftBox
                            component="td"
                            textAlign="center"
                            sx={{
                              borderRight:
                                i18n.language === "ar" ? 0 : "2px solid black",
                              borderLeft:
                                i18n.language === "ar" ? "2px solid black" : 0,
                            }}
                          >
                            &nbsp;
                          </SoftBox>
                          <SoftBox component="td" textAlign="center">
                            &nbsp;
                          </SoftBox>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </SoftBox>
              </SoftBox>

              <SoftBox>
                <Typography variant="subtitle2" px={3} pt={3} pb={1}>
                  {t("Timbre fiscal, Article 124, Loi 53/93")}: 1.000
                </Typography>

                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  justifyContent="space-between"
                  px={3}
                >
                  {/* <Grid item xs={12} md={6}>
                    <SoftBox
                      sx={{
                        border: "2px solid black",
                        borderRadius: 4,
                        overflow: "hidden",
                      }}
                    >
                      <Table sx={{ borderCollapse: "collapse" }}>
                        <TableBody>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Taux de TVA")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                            >
                              <SoftTypography variant="subtitle2">
                                6.000
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                            >
                              <SoftTypography variant="subtitle2">
                                12.00
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                            >
                              <SoftTypography variant="subtitle2">
                                18.00
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Base")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Montant")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Poids")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                615.805
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                179.958
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </Grid> */}
                  <Grid item xs={12} md={4.5}>
                    <SoftBox
                      sx={{
                        border: "2px solid black",
                        borderRadius: 4,
                        overflow: "hidden",
                      }}
                    >
                      <Table sx={{ borderCollapse: "collapse" }}>
                        <TableBody>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              pt={1.5}
                              pb={1}
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Total HT")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              pt={1.5}
                              pb={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                            >
                              <SoftTypography variant="subtitle2">
                                {Number(order?.total_price).toFixed(3)}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Total TTC")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              pt={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                            >
                              <SoftTypography variant="subtitle2">
                                {(
                                  Number(order?.total_price) +
                                  Number(companyInfos.stamp_value)
                                ).toFixed(3)}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              pb={1.5}
                              pt={1}
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Acompte fiscal")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              pb={1}
                              pt={1}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                            >
                              <SoftTypography variant="subtitle2">
                                0.000
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                          <TableRow>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1}
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                {t("Montant net à payer")}
                              </SoftTypography>
                            </SoftBox>
                            <SoftBox
                              component="td"
                              textAlign={
                                i18n.language === "ar" ? "right" : "left"
                              }
                              px={1.5}
                              py={1.5}
                              borderRight={
                                i18n.language === "ar" ? "2px solid black" : 0
                              }
                              borderLeft={
                                i18n.language === "ar" ? 0 : "2px solid black"
                              }
                              borderTop="2px solid black"
                            >
                              <SoftTypography variant="subtitle2">
                                {(
                                  Number(order?.total_price) +
                                  Number(companyInfos.stamp_value)
                                ).toFixed(3)}
                              </SoftTypography>
                            </SoftBox>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </SoftBox>
                  </Grid>

                  <Grid item xs={12} py={3}>
                    <SoftBox
                      p={3}
                      sx={{
                        border: "2px solid black",
                        borderRadius: 2,
                        overflow: "hidden",
                      }}
                    >
                      <Typography variant="subtitle2">
                        {t("Montant net en toutes lettres")}:
                        {i18n.language === "fr"
                          ? numberToWords(
                              (
                                Number(order?.total_price) +
                                Number(companyInfos.stamp_value)
                              ).toFixed(3)
                            )
                          : i18n.language === "en"
                          ? numberToWordsEnglish(
                              (
                                Number(order?.total_price) +
                                Number(companyInfos.stamp_value)
                              ).toFixed(3)
                            )
                          : numberToWordsArabic(
                              (
                                Number(order?.total_price) +
                                Number(companyInfos.stamp_value)
                              ).toFixed(3)
                            )}
                      </Typography>
                    </SoftBox>
                  </Grid>
                </Grid>
              </SoftBox>
            </Card>
            <SoftBox
              width="100%"
              p={3}
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
            >
              <Grid
                container
                spacing={2}
                justifyContent="flex-end"
                alignItems="center"
              >
                <Grid item xs={12} sm="auto">
                  <SoftBox
                    width="100%"
                    display="flex"
                    justifyContent={{ xs: "space-between", sm: "flex-start" }}
                    alignItems="center"
                    flexWrap="wrap"
                  >
                    {/* Arabic Download Button */}
                    <SoftButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        i18n.changeLanguage("ar");
                        downloadInvoice(deliveryNoteDetails?.number);
                      }}
                      sx={{
                        marginBottom: { xs: 2, sm: 0 }, // Margin for smaller screens
                        marginRight: { sm: 2 }, // Right margin for larger screens
                      }}
                    >
                      {t("Télécharger la facture en arabe")}
                    </SoftButton>

                    {/* Regular Download Button */}
                    <SoftButton
                      variant="gradient"
                      color="info"
                      onClick={() => {
                        downloadInvoice(deliveryNoteDetails?.number);
                      }}
                    >
                      {t("Télécharger")}
                    </SoftButton>
                  </SoftBox>
                </Grid>
              </Grid>
            </SoftBox>
          </Grid>
        </Grid>
      </SoftBox>
    </DashboardLayout>
  );
}

export default DeliveryNoteDetails;
